import theme from '../../utils/theme/theme';

export const DefaultCheckboxStyles = {
  CheckboxContainerStyles: {
    paddingLeft: 0
  },
  CheckboxStyles:{
    paddingRight: 5
  },
  LabelStyles: {
    color: theme.palette.custom.grey,
    fontSize: '14px',
    fontWeight: 600,
  },
  SubLabelStyles: {
    color: theme.palette.custom.mediumDarkGrey,
    fontSize: '14px',
    marginLeft: '28px',
    marginTop: '-8px',
  }
};

export const ChildCheckboxStyles = {  
  ...DefaultCheckboxStyles, 
  CheckboxContainerStyles: {
    ...DefaultCheckboxStyles.CheckboxContainerStyles,
    paddingLeft: '2.25rem'
  },
  LabelStyles: {
    ...DefaultCheckboxStyles.LabelStyles,
    fontWeight: 'normal'
  },
};