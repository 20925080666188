import {
  DEFAULT_PLAYER_GAME_ORDERING,
  baseFetchPlayerSpanStats,
  baseFetchPlayerGameStats,
  baseFetchPlayerStreakStats,
  baseFetchTeamGameStats,
} from './api';
import API_URLS from './api_urls';

export const fetchNbaPlayerSpanStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchPlayerSpanStats(
  API_URLS.nba.player_span_finder, filters, signOut, page, sortBy, uuid
);

export const fetchNbaPlayerGameStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchPlayerGameStats(API_URLS.nba.player_game_finder, filters, signOut, page, sortBy, uuid)

export const fetchNbaPlayerStreakStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchPlayerStreakStats(
  API_URLS.nba.player_streak_finder, filters, signOut, page, sortBy, uuid
);

export const fetchNbaTeamGameStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchTeamGameStats(
  API_URLS.nba.team_game_finder, filters, signOut, page, sortBy, uuid
);

export const fetchNbaComebackStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchTeamGameStats(
  API_URLS.nba.team_comebacks_finder, filters, signOut, page, sortBy, uuid
);

export const fetchNbaTeamStreakStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchTeamGameStats(
  API_URLS.nba.team_streak_finder, filters, signOut, page, sortBy, uuid
);

export const fetchNbaTeamSpanStats = async (
  filters: Array<string>,
  signOut: () => boolean,
  page = 0,
  sortBy = DEFAULT_PLAYER_GAME_ORDERING,
  uuid?: string,
) => baseFetchTeamGameStats(
  API_URLS.nba.team_span_finder, filters, signOut, page, sortBy, uuid
);
