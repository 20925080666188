import React from 'react';
import { Stack, Typography } from '@mui/material';
import { formatDate } from '../../utils/common/date';

interface IProps {
  date: string;
  venue: string;  
  tournamentName?: string;
  tournamentRound?: string;
}

function BoxScoreHeader({ date, venue, tournamentName, tournamentRound }: IProps) {
  const dateDisplay = formatDate(date, "LLLL d, yyyy");

  return (
    <Stack
      direction='row'
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Stack 
        direction="column" 
        spacing={0}
        paddingBottom={2}>
      <Typography
        component="h4"
        variant="h6"
        fontWeight="600"
        padding={2}
        paddingBottom={0}
      >
        { dateDisplay }
      </Typography>
        { tournamentName &&
          <Typography
            component="div"
            variant="body1"
            fontSize='1.125em'
            padding={0}
            paddingLeft={2}
            paddingTop={0.5}
          >            
            {tournamentName}{tournamentRound ? `, ${tournamentRound}` : ''}
          </Typography>
        }
      </Stack>
      
      <Typography
        component="div"
        variant='body1'
        padding={2}
      >
        { venue }
      </Typography>
    </Stack>
  );
}

export default BoxScoreHeader;
