import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  DefaultCheckboxStyles,
  ChildCheckboxStyles,
} from './StyleOverrides';

interface IProps {
  label: string;
  subLabel?: string;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void;
}

function ModalCheckbox({
  label,
  subLabel,
  labelPlacement,
  className = 'elias-checkbox',
  selected = false,
  disabled = false,
  onChange = () => {},
}: IProps) {

  let currentStyles;

  switch (className) {
    case 'elias-checkbox-child':
      currentStyles = ChildCheckboxStyles;
      break;

    default:
      currentStyles = DefaultCheckboxStyles;
      break;
  }

  return (
    <Box style={currentStyles.CheckboxContainerStyles}>
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            style={currentStyles.CheckboxStyles}
            inputProps={{
              'aria-label': `${label}-checkbox`,
            }}
          />
        }
        label={<FormHelperText style={currentStyles.LabelStyles}>{label}</FormHelperText>}
        labelPlacement={labelPlacement ?? 'end'}
        checked={selected}
        disabled={disabled ?? false}
        onChange={onChange}
        data-testid={`${label}-checkbox`}
      />
      {subLabel && (
        <FormHelperText sx={currentStyles.SubLabelStyles}>{subLabel}</FormHelperText>
      )}
    </Box>
  );
}

export default ModalCheckbox;