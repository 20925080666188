import React from 'react';
import { Grid, styled, Tab, Tabs, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import TeamHistoryTab from './TeamHistoryTab';
import TeamSeasonsTab from './TeamSeasonsTab';
import { FranchiseDetailType, FranchiseHistoryType } from '../../types/franchise';
import { useLeagueContext } from '../../contexts/LeagueContext';
import { getTeamLogoUrl } from '../../utils/common/teams';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

interface IProps {
  teamId: string;
  franchiseDetailData: FranchiseDetailType[];
  franchiseHistoryData: FranchiseHistoryType[];
}

const StyledLogo = styled('img')({
  width: '200px',
  height: '200px',
  objectFit: 'contain',
  marginRight: '10px',
});

function getTeamHeading(league: string) {
  switch (league) {
    case 'ncaambb':
      return 'Men\'s Basketball';
    case 'ncaawbb':
      return 'Women\'s Basketball';
    default:
      return '';
  }
}

function TeamLayout({ teamId, franchiseDetailData, franchiseHistoryData}: IProps) {
  const [value, setValue] = React.useState(0);
  const leagueContext: any = useLeagueContext();
  const league = leagueContext.selectedLeague;
  const teamHeading = getTeamHeading(league);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const teamDetails: FranchiseDetailType = franchiseDetailData[0];
  const teamLogoUrl = getTeamLogoUrl({ league, franchiseId: teamDetails.franchise });

  return (
    <Grid container direction="column">
      <Grid container direction="row" marginY={5}>
        <Grid item>
            {
              teamLogoUrl && 
              <StyledLogo 
                src={teamLogoUrl}
                alt={ `${teamDetails.nickname} Logo` }
              />
            }
        </Grid>
        <Grid item>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} justifyContent='center'>
            <Typography component="h1" variant="h5" fontWeight="600">{teamDetails.full_name} {teamDetails.nickname}</Typography>
            <Typography component="div" variant='body1'>{teamDetails.short_name}, {teamHeading}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="navigation tabs">
          <Tab label="History" />
          <Tab label="Seasons" />
        </Tabs>
      </Grid>
      <Grid item>
        <TabPanel value={value} index={0}>
          <TeamHistoryTab teamDetail={teamDetails} teamHistory={franchiseHistoryData} teamHeading={teamHeading} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TeamSeasonsTab teamId={teamId} teamHeading={teamHeading} />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default TeamLayout;
