import React from 'react';
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TeamLink from './TeamLink';
import { range } from '../../utils/common/number';

const StyledTableCell = styled(TableCell)({
  padding: 4,
});

const StyledTableHeaderCell = styled(TableCell)({
  padding: 4,
  fontWeight: 650,
});

const StyledTotalCell = styled(TableCell)({
  padding: 4,
  fontWeight: 650,
});

const StyledLogo = styled('img')({
  width: '15px',
  height: '15px',
  objectFit: 'contain',
  marginRight: '10px',
});

interface ITeamProps {
  teamName: string;
  teamRecord: string;
  teamScore: number;
  teamLink: string;
  teamLogoUrl?: string;
  teamWin: boolean;
  firstHalfScore?: number;
  secondHalfScore?: number;
  overtime1Score?: number;
  overtime2Score?: number;
  overtime3Score?: number;
  overtime4Score?: number;
  overtime5Score?: number;
  overtime6Score?: number;
  overtimeScore?: number;
  quarter1Score?: number;
  quarter2Score?: number;
  quarter3Score?: number;
  quarter4Score?: number;
};

interface IProps {
  home: ITeamProps,
  homeTeamLogoUrl?: string,
  visitor: ITeamProps,
  visitorTeamLogoUrl?: string,
  numberOfOvertimes: number,
};

// visiting team is always displayed on top
function BoxScoreSplitStats({
  home,
  homeTeamLogoUrl,
  visitor,
  visitorTeamLogoUrl,
  numberOfOvertimes,
}: IProps) {
  const hasOvertime = numberOfOvertimes > 0;
  const hasQuarters = home.quarter1Score != null;
  const overtimeNumbers: number[] = hasOvertime ? range(1, numberOfOvertimes) : [];
  const scoreColumnCount: number = (hasQuarters ? 4 : 2) + overtimeNumbers.length + 1;
  
  // Adjust the padding to the left of the table with the quarter/half/overtime scores depending on how many of these columns there are.
  let boxPaddingLeft: number = 4;
  if (scoreColumnCount <= 3) {
    boxPaddingLeft = 18;
  }
  else if (scoreColumnCount <= 5) {
    boxPaddingLeft = 10;
  }
  return (
    <Box
      paddingRight={2}
      paddingLeft={boxPaddingLeft}
      display='flex'
      height='100%'
      justifyContent='center'
      justifyItems='center'
      alignItems='center'
      alignContent='center'
    >
      <Table aria-label="simple table" padding='none'>
        <TableHead>
          <TableRow>
            <StyledTableHeaderCell />
            <StyledTableHeaderCell align="center">1</StyledTableHeaderCell>
            <StyledTableHeaderCell align="center">2</StyledTableHeaderCell>
            {
              (hasQuarters) && 
              <>
                <StyledTableHeaderCell align="center">3</StyledTableHeaderCell>
                <StyledTableHeaderCell align="center">4</StyledTableHeaderCell>
              </>
            }
            { (hasOvertime) &&
              overtimeNumbers.map( (otNum) => (<StyledTableHeaderCell align="center" key={`ot_header_${otNum}`}>OT{otNum.toString()}</StyledTableHeaderCell>))
            }
            <StyledTableHeaderCell align="center">T</StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={1}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <StyledTableCell component="th" scope="row">
              {
                visitorTeamLogoUrl && 
                <StyledLogo
                  src={visitorTeamLogoUrl}
                  alt={ `${visitor.teamName} Logo` }
                />
              }
              <TeamLink link={ visitor.teamLink } name={ visitor.teamName } />
            </StyledTableCell>
            <StyledTableCell align="center">{ hasQuarters ? visitor.quarter1Score : visitor.firstHalfScore }</StyledTableCell>
            <StyledTableCell align="center">{ hasQuarters ? visitor.quarter2Score : visitor.secondHalfScore }</StyledTableCell>
            {
              (hasQuarters) && 
              <>
                <StyledTableCell align="center">{ visitor.quarter3Score }</StyledTableCell>
                <StyledTableCell align="center">{ visitor.quarter4Score }</StyledTableCell>
              </>
            }
            { (hasOvertime) &&
              overtimeNumbers.map( (otNum) => (<StyledTableHeaderCell align="center" key={`ot_${otNum}_visitor`}>{ visitor[(`overtime${otNum.toString()}Score`) as keyof ITeamProps] }</StyledTableHeaderCell>))
            }
            <StyledTotalCell align="center">{ visitor.teamScore }</StyledTotalCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <StyledTableCell component="th" scope="row">
              {
                homeTeamLogoUrl && 
                <StyledLogo 
                  src={homeTeamLogoUrl}
                  alt={ `${home.teamName} Logo` }
                />
              }
              <TeamLink link={ home.teamLink } name={ home.teamName } />
            </StyledTableCell>
            <StyledTableCell align="center">{ hasQuarters ? home.quarter1Score : home.firstHalfScore }</StyledTableCell>
            <StyledTableCell align="center">{ hasQuarters ? home.quarter2Score : home.secondHalfScore }</StyledTableCell>
            {
              (hasQuarters) && 
              <>
                <StyledTableCell align="center">{ home.quarter3Score }</StyledTableCell>
                <StyledTableCell align="center">{ home.quarter4Score }</StyledTableCell>
              </>
            }
            { (hasOvertime) &&
              overtimeNumbers.map( (otNum) => (<StyledTableHeaderCell align="center" key={`ot_${otNum}_home`}>{ home[(`overtime${otNum.toString()}Score`) as keyof ITeamProps] }</StyledTableHeaderCell>))
            }
            <StyledTotalCell align="center">{ home.teamScore }</StyledTotalCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default BoxScoreSplitStats;
