import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Close } from '@mui/icons-material';


const StyledClose = styled(Close)(({ theme }) => ({
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    '&:hover': {
        color: theme.palette.primary.main
    }
}));

interface IProps {
    heading?: string;
    subheading?: string;
    buttonContextualInfo?: string;
    buttonText?: string;
    children: React.ReactNode;
}

export default function ModalOverlay({ buttonContextualInfo, buttonText = 'See more', heading, subheading, children }: IProps) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box sx={{ typography: 'body1', fontStyle: 'normal' }}>
            { buttonContextualInfo && buttonContextualInfo }
            <Button onClick={handleOpen} sx={{ typography: 'body1', fontStyle: 'normal', textTransform: 'none' }}>
                { buttonText }
            </Button>
            <Modal 
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '75vw',
                    maxHeight: '75vh',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px',
                    p: 4,
                    overflowY: 'scroll'
                }}>
                    <StyledClose 
                        onClick={handleClose} 
                        theme={ theme } 
                        role="button"
                        aria-label="Close modal"
                        aria-controls="modal-modal-title modal-modal-description"
                    />
                    { heading && (
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            { heading }
                        </Typography>
                    )}
                    { subheading && (
                        <Typography id="modal-modal-description" style={{ marginBottom: '20px' }} >
                            { subheading}
                        </Typography>
                    )}
                    { children }
                </Box>
            </Modal>
        </Box>
    );
}