import React from 'react';
import { AuthProvider, createRefresh } from 'react-auth-kit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { styled, ThemeProvider } from '@mui/material/styles';
import '@fontsource/inter';

import { ROUTES } from './components/utils/router/Routes';
import theme from './components/utils/theme/theme';
import LeagueContextProvider from './components/contexts/LeagueContext';
import { doRefresh, doLogout, setDatadogUser } from './api/auth';

const StyledApp = styled('div')(() => ({
  [`a:not([class])`]: {
    color: theme.palette.primary.main,
  },
  [`a:not([class]):visited`]: {
    color: theme.palette.secondary.main,
  }
}));

function App() {
  const queryClient = new QueryClient();

  const tokenRefresh = createRefresh({
    interval: 5,
    refreshApiCallback: async(params) => {
      const { refreshToken } = params;
      const response = await doRefresh(refreshToken || '');

      if (response.status !== 200) {
        doLogout();
        return {
          isSuccess: false,
          newAuthToken: "",
        };
      }

      const { data } = response;
      const newAuthToken = data.access;
      const newAuthTokenExp = new Date(data.access_token_expiration);

      setDatadogUser(params.authUserState, response);

      return {
        isSuccess: true,
        newAuthToken,
        newAuthTokenExpireIn: newAuthTokenExp.valueOf() - Date.now().valueOf(),
      };
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider
        authType='cookie'
        authName='ae-auth'
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
        refresh={tokenRefresh}
      >
        <QueryClientProvider client={queryClient}>
          <LeagueContextProvider>
            <StyledApp className="App">
              <RouterProvider router={ROUTES} />
            </StyledApp>
            <ReactQueryDevtools initialIsOpen={false} />
          </LeagueContextProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
