import { NCAA_TOURNAMENT_ID, POST_SEASON_TOURNAMENTS, CONF_TOURNAMENTS, NCAA_ROUNDS, DEFAULT_TOURNAMENT_ROUND_LABELS } from "../ncaambb/constants";
import { POST_SEASON_TOURNAMENTS as NCW_POST_SEASON_TOURNAMENTS,  } from "../ncaawbb/constants";
import { teamAdvancedModalFilterCategories as ncmTeamAdvancedModalFilterCategories } from "../../pages/NcaaMbb/constants";
import { teamAdvancedModalFilterCategories as ncwTeamAdvancedModalFilterCategories } from "../../pages/NcaaWbb/constants";
import { ModalFilterCategoriesType } from "../../types/modal";
import { LeagueType } from "../../contexts/LeagueContext";

export const getKeyByValue = (object: any, value: any): string | undefined => Object.keys(object).find(key => object[key] === value);

const getFilterCategoryType = (league: LeagueType, category: string): ModalFilterCategoriesType | undefined => {
  const teamAdvancedModalFilterCategories = league === 'ncaambb' ? ncmTeamAdvancedModalFilterCategories : ncwTeamAdvancedModalFilterCategories;
  return teamAdvancedModalFilterCategories.find(c => c.category === category);
}

const getFilterLabel = (league: LeagueType, category: string, filterId: string): string => {  
  const filterCategoryType: ModalFilterCategoriesType | undefined = getFilterCategoryType(league, category);
  const filterLabel: string = filterCategoryType?.filters?.find(f => f.id === filterId)?.label || '';

  return filterLabel;
}

export const formatTournamentNameDisplay = (league: LeagueType, tournamentId: number, tournamentName: string): string => {
  let tournamentNameDisplay: string = '';
  let tournamentKey: string | undefined;
  let postSeasonTournaments = POST_SEASON_TOURNAMENTS;
  if (league === 'ncaawbb') {
    postSeasonTournaments = NCW_POST_SEASON_TOURNAMENTS;
  }


  switch (league) {
    case 'ncaambb':
    case 'ncaawbb':
      tournamentKey = getKeyByValue(postSeasonTournaments, tournamentId);

      if (tournamentKey) {
        if (tournamentId === NCAA_TOURNAMENT_ID) {
          tournamentNameDisplay = getFilterCategoryType(league, 'ncaa_tournament')?.categoryLabel || '';
        }
        else {
          tournamentNameDisplay = getFilterLabel(league, 'other_postseason_tournaments', tournamentKey);
        }
      }
      else {
        tournamentKey = getKeyByValue(CONF_TOURNAMENTS, tournamentId);

        if (tournamentKey) {
          tournamentNameDisplay = getFilterLabel(league, 'conference_tournaments', tournamentKey);
        }
      }
      break;

    default:
      break;
  }

  return tournamentNameDisplay ?? tournamentName;
};

export const formatRoundNameDisplay = (league: LeagueType, tournamentId: number, roundAbbreviation: string): string => {
  let roundNameDisplay: string = '';
  let roundKey: string | undefined;

  switch (league) {
    case 'ncaambb':
    case 'ncaawbb':
      if (tournamentId === NCAA_TOURNAMENT_ID) {
        roundKey = getKeyByValue(NCAA_ROUNDS, roundAbbreviation);

        if (roundKey) {
          roundNameDisplay = getFilterCategoryType(league, 'ncaa_tournament')?.filters.find(f => f.id === roundKey)?.label || '';
        }
      }
      else {
        roundNameDisplay = DEFAULT_TOURNAMENT_ROUND_LABELS[roundAbbreviation];
      }
      break;

    default:
      break;
  }

  return roundNameDisplay ?? roundAbbreviation;
}