export const AE2_URL = process.env.REACT_APP_AE2_URL;

export const SPORT_LEAGUES = {
  NBA: 'NBA',
  NHL: 'NHL',
  MLB: 'MLB',
  NFL: 'NFL',
  WNBA: 'WNBA',
};

export const FINDER_TYPES = {
  PLAYER_GAME: 'PLAYER_GAME',
  PLAYER_STREAK: 'PLAYER_STREAK',
  PLAYER_SPAN: 'PLAYER_SPAN',
  TEAM_GAME: 'TEAM_GAME',
  TEAM_STREAK: 'TEAM_STREAK',
  TEAM_SPAN: 'TEAM_SPAN'
};

export const FEATURE_TOGGLES = {
  teamPages: true,
  playerPages: true,
  gamePages: true,
}

export const DEBOUNCE_DELAY = 350;

export const LOGO_BASE_URL = 'https://ae3images.blob.core.windows.net/ncaa-d1/';
