import React, { createContext, useContext, useReducer } from "react";

interface IProps {
  children?: React.ReactNode;
}

export type LeagueType = 'nba' | 'wnba' | 'ncaambb' | 'ncaawbb';

const initialState = {
  selectedLeague: 'nba',
}

export const LeagueContext: any = createContext(null);
export const LeagueDispatchContext: any = createContext(null);

export const useLeagueContext = () => useContext(LeagueContext);
export const useLeagueDispatchContext = () => useContext(LeagueDispatchContext);

const leagueReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'leagueChanged':
      return {
        ...state,
        selectedLeague: action.selectedLeague,
      };
    default:
      return state;
  }
};

function LeagueContextProvider({ children }: IProps) {
  const [contextState, dispatch] = useReducer(
    leagueReducer,
    initialState
  );

  return (
    <LeagueContext.Provider value={contextState}>
      <LeagueDispatchContext.Provider value={dispatch}>
        {children}
      </LeagueDispatchContext.Provider>
    </LeagueContext.Provider>
  );
};

export default LeagueContextProvider;