import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { teamAdvancedModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaMbbTeamFiltersLayout from '../../../compounds/ncaambb/NcaaMbbTeamFiltersLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import TeamGameGroupBy from '../../../compounds/TeamGameGroupBy/TeamGameGroupBy';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/ncaambb/search';
import { FINDER_TYPES } from '../../../utils/common/constants';

interface IProps extends WithCopyableUrlProps {};

function TeamGameFinderSearch({ handleDone }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const groupByPartial = <TeamGameGroupBy />
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={teamAdvancedModalFilterCategories}
      filterType='advancedFilters'
      finderType={FINDER_TYPES.TEAM_GAME}
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <NcaaMbbTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.ncaambb}
            bottomModalPartial={bottomModalPartial}
            groupByPartial={groupByPartial}
          />
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  TeamGameFinderSearch,
  ROUTER_URLS.ncaambb.teamGameFinderResults,
  statsFilters,
  appendContextFilters,
  FINDER_TYPES.TEAM_GAME,
  teamAdvancedModalFilterCategories,
);
