import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, streakRangeOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { teamAdvancedModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaWbbTeamFiltersLayout from '../../../compounds/ncaawbb/NcaaWbbTeamFiltersLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import PlayerStreakFilters from '../../../compounds/PlayerStreakFilters/PlayerStreakFilters';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/ncaawbb/search';
import { FINDER_TYPES } from '../../../utils/common/constants';

interface IProps extends WithCopyableUrlProps {};

function TeamStreakFinderSearch({ handleDone }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={teamAdvancedModalFilterCategories}
      filterType='advancedFilters'
      finderType={FINDER_TYPES.TEAM_STREAK}
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <NcaaWbbTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.ncaawbb}
            bottomModalPartial={bottomModalPartial}
          >
            <PlayerStreakFilters streakRangeOptions={streakRangeOptions} />
          </NcaaWbbTeamFiltersLayout>
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  TeamStreakFinderSearch,
  ROUTER_URLS.ncaawbb.teamStreakFinderResults,
  statsFilters,
  appendContextFilters,
  FINDER_TYPES.TEAM_STREAK,
  teamAdvancedModalFilterCategories,
);
