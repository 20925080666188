import { Grid } from '@mui/material';
import React from 'react';
import { ModalFilterType, ModalFilterChildType } from '../../types/modal';
import { EqualitySignType } from '../../utils/stats/stats-utils';
import { EliasEqualityButtons } from '../EliasEqualityButtons/EliasEqualityButtons';
import EliasNumericInput from '../EliasInput/EliasNumericInput';
import ModalCheckbox from '../ModalCheckbox/ModalCheckbox';
import { ContextFilterChildType } from '../../types/context';

interface IProps {
  filter: ModalFilterType;

  selected: boolean;
  selectedEquality: string;
  inputValue: string;
  contextFilterChildTypes?: ContextFilterChildType[];
  finderType?: string;

  onCheckBoxChange?: (
    id: string,
    checked: boolean,
    equality: string,
    chip: string,
  ) => void;
  onChildCheckBoxChange?: (
    parentId: string,
    childId: string,
    checked: boolean,    
  ) => void;
  onInputValueChange?: (
    option: string,
    value: string,
    equalityOption: string,
    chipAbbr: string,
  ) => void;
};


function ModalEqualityNumeric({
  filter,

  selected = false,
  selectedEquality,
  inputValue,
  contextFilterChildTypes,
  finderType = '',

  onCheckBoxChange = () => {},
  onChildCheckBoxChange = () => {},
  onInputValueChange = () => {},
}: IProps) {

  const handleCheckBoxChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    onCheckBoxChange(filter.id, checked, selectedEquality, filter.chip)
  };

  const handleChildCheckBoxChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean, childId: string) => {
    onChildCheckBoxChange(filter.id, childId, checked);
  };

  const handleEqualityButtonClick = (
    sign: EqualitySignType,
  ) => {
    onInputValueChange(filter.id, inputValue, sign, filter.chip);
  };

  const handleInputChange = (value: string) => {
    onInputValueChange(filter.id, value, selectedEquality, filter.chip);
  };

  const mapFilterChildTypes = (childTypes: ModalFilterChildType[]) =>
    childTypes.map((childType) => {
      const contextFilterChildType: ContextFilterChildType | undefined = contextFilterChildTypes?.find(filterChildType => filterChildType.id === childType.id);

      // If finderTypes exist, return null if finderType is not in the finderTypes array.      
      if (childType.finderTypes && childType.finderTypes.indexOf(finderType) <= -1) {
        return null;
      }

      // finderTypes does not exist or finderType is in the finderTypes array - return the element.
      switch (childType.filterType) {
        // filterType = 'checkbox'
        default:          
        return (
            <ModalCheckbox
                key={`${childType.id}-option`}
                label={childType.label}
                labelPlacement="end"
                selected={contextFilterChildType?.selected}
                disabled={contextFilterChildType?.disabled}
                className='elias-checkbox-child'
                onChange={(e, checked: boolean) => {handleChildCheckBoxChange(e, checked, childType.id)}}
              />
          )
      }
    });

  return (
    <Grid container key={`${filter.id}-equality-row`}>
      <Grid item xs={9}>
        <ModalCheckbox
          key={`${filter.id}-option`}
          label={filter.label}
          subLabel={filter.subLabel}
          labelPlacement="end"
          selected={selected}
          onChange={handleCheckBoxChange}
        />
        {
          (filter.childTypes) &&
            mapFilterChildTypes(filter.childTypes)
        }
      </Grid>
      <Grid item xs={3} display='flex' alignItems='start' justifyContent='end' marginTop='8px'>
        <EliasEqualityButtons
          selected={selectedEquality}
          onButtonClick={handleEqualityButtonClick}
          filterId={filter.id}
          filterAbbreviation={filter.label}
        />
        <EliasNumericInput
          id={filter.id}
          value={inputValue}
          onChange={handleInputChange}
          ariaLabel={`${filter.label}-label`}
        />
      </Grid>
    </Grid>
  );
}

export default ModalEqualityNumeric;
