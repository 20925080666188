import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { Grid } from '@mui/material';
import { EqualitySignType, EqualitySigns } from '../../utils/stats/stats-utils';
import {
  StatFilter,
  StatFilterType,
} from './filter-stats-utils';
import { EliasBasicOptionRow, EliasEqualityOptionRow } from './EliasOptionRows';
import {
  OptionListContainerStyles,
  OptionsListItemStyles,
} from './StyleOverrides';
import './EliasOptionList.scss';
import { SelectedStatFilterType } from '../../types/statsFilter';

interface OptionsListProps {
  onEqualityButtonClick: (
    sign: EqualitySignType,
    filterId: string,
    filterAbbreviation: string
  ) => void;
  onValueChange: (
    value: string,
    filterId: string,
    filterAbbreviation: string
  ) => void;
  filter: string;
  selectedFilterChips: SelectedStatFilterType[];
  statsFilterOptions: StatFilter[];
  displayYearInfo: boolean;
}

function EliasOptionsList({
  filter,
  onEqualityButtonClick,
  onValueChange,
  selectedFilterChips,
  statsFilterOptions,
  displayYearInfo,
}: OptionsListProps) {
  const filteredStats: StatFilter[] = filter
    ? statsFilterOptions.filter(
        (stat: StatFilter) =>
          stat.abbr === filter ||
          filter.includes(stat.abbr) ||
          filter.includes(stat.name.toUpperCase()) ||
          stat.abbr.includes(filter) ||
          stat.name.toUpperCase().includes(filter)
      )
    : statsFilterOptions;

  const [currentStat, setCurrentStat] = React.useState<StatFilter | undefined>(
    undefined
  );

  const getStatValue = (stat: string): string => {
    const selectedFilter = selectedFilterChips.find((c) => c.id === stat);
    return selectedFilter ? selectedFilter.value || '' : '';
  };

  const getEqualityValue = (stat: string) => {
    const selectedFilter = selectedFilterChips.find((c) => c.id === stat);
    const gte = EqualitySigns['>='];
    return selectedFilter ? selectedFilter.equalitySymbol || gte : gte;
  };

  const statIsSelected = (stat: string) =>
    selectedFilterChips.find((c) => c.id === stat);

  const resetOption = () => {
    setCurrentStat(undefined);
  };

  React.useEffect(() => {
    if (filteredStats.length && currentStat) {
      resetOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSwitch = (statFilter: StatFilter) => {
    const selected = !!statIsSelected(statFilter.id);
    switch (statFilter.type) {
      case StatFilterType.equality:
        return (
          <EliasEqualityOptionRow
            statFilter={statFilter}
            onButtonClick={onEqualityButtonClick}
            value={getStatValue(statFilter.id)}
            onValueChange={onValueChange}
            focusedOption={currentStat?.abbr ?? ''}
            selectedEquality={getEqualityValue(statFilter.id)}
            ariaLabel={`${statFilter.abbr}-label`}
            selected={selected}
            type="equality"
            displayYearInfo={displayYearInfo}
          />
        );
      case StatFilterType.boolean:
        return (
          <EliasEqualityOptionRow
            statFilter={statFilter}
            onButtonClick={onEqualityButtonClick}
            value={getStatValue(statFilter.id)}
            onValueChange={onValueChange}
            focusedOption={currentStat?.abbr ?? ''}
            selectedEquality={EqualitySigns['=']}
            ariaLabel={`${statFilter.abbr}-label`}
            selected={selected}
            type="boolean"
            displayYearInfo={displayYearInfo}
          />
        );
      default:
        return <EliasBasicOptionRow statFilter={statFilter} />;
    }
  };

  const noResultsPartial = (
    <ListItem
      key='filter-no-items'
      sx={OptionsListItemStyles}
      className='option'
      tabIndex={0}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        rowSpacing={2}
      >
        <span style={{ marginRight: '10px' }}>No matches found.</span>
      </Grid>
    </ListItem>
  );

  return (
    <List id="stats-filter-list" sx={OptionListContainerStyles}>
      { filteredStats && filteredStats.length > 0 ?
        filteredStats.map((stat) => (
          <ListItem
            key={`filter-${stat.id}`}
            sx={OptionsListItemStyles}
            className={`option ${
              statIsSelected(stat.id) ? 'selected' : ''
            }`}
            tabIndex={0}
          >
            {renderSwitch(stat)}
          </ListItem>
        )) :
        noResultsPartial
      }
    </List>
  );
}

export default EliasOptionsList;
