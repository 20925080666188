import React from "react";

const backInTimeInfo = [
  {
    categoryName: 'D1 Schools',
    teamRecord: '2009-10',
    gameScores: '2009-10',
    playerAndTeamStats: '2009-10',
    notes: <>Exceptions that go further back than 2009-10:
      <ul style={{ paddingLeft: '20px' }}>
        <li>D1 team W-L records complete back to 1981-82, or the teams first season</li>
        <li>Big East game scores complete back to 2002-03</li>
        <li>Creighton, DePaul, UConn, Villanova games scores complete back to 1981-82</li>
      </ul>
    </>,
  },
  {
    categoryName: 'NCAA Tournament',
    teamRecord: '2009-10',
    gameScores: '1981-82',
    playerAndTeamStats: '2009-10',
    notes: '',
  },
  {
    categoryName: 'WNIT Tournament',
    teamRecord: '2009-10',
    gameScores: '2008-09',
    playerAndTeamStats: '2009-10',
    notes: '',
  },
  {
    categoryName: 'Poll Ranked Teams',
    teamRecord: '2002-03',
    gameScores: '2002-03',
    playerAndTeamStats: '2009-10',
    notes: '',
  },
];

export default backInTimeInfo;







																
										
