import React from 'react';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface IProps {
  text: string;
  Icon: React.ReactNode;
  children: React.ReactNode;

}

function ExpandableNavListItem({ text, Icon, children }: IProps) {
  const [subNavOpen, setSubNavOpen] = React.useState(true);

  const handleClickExapndable = () => {
    setSubNavOpen(!subNavOpen);
  };

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClickExapndable}>
          <ListItemIcon sx={{ minWidth: 44 }}>
            { Icon }
          </ListItemIcon>
          <ListItemText primary={text} />
          {subNavOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={subNavOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          { children }
        </List>
      </Collapse>
    </>
  );
}

export default ExpandableNavListItem;
