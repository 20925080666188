import React from 'react';
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridRowModel,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import Box from '@mui/system/Box';
import './EliasDataGrid.scss';
import { generateCellClass, renderCell } from './data-grid-helpers';
import { DEFAULT_PAGE_SIZE } from '../../../api/api';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { ExternalLeagueRoutesType } from '../../utils/router/external_urls';
import { ColumnMappingType } from '../../types/results';
import LastUpdated from './LastUpdated';


interface EliasDataGridProps {
  columns: GridColDef[];
  rows: GridRowModel[];
  rowCount: number;
  handleDone: (sortByParam: string, pageParam: number) => void;
  linkUrls: ExternalLeagueRoutesType;
}

const DEFAULT_ROW_HEIGHT = 44;

function EliasDataGrid({
  columns,
  rows,
  rowCount,
  handleDone,
  linkUrls,
}: EliasDataGridProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();
  // Using split here to handle potential secondary sorts (re: AE30-934)
  const sortBy: string = (filterContext.sortBy && filterContext.sortBy.split(',')[0]) || '-game__date';

  const initialSortByDirection = sortBy[0] === "-" ? "desc" : "asc";
  const initialSortByField = sortBy[0] === "-" ? sortBy.substring(1) : sortBy;

  const [sortByDirection, setSortByDirection] = React.useState<GridSortDirection>(initialSortByDirection);
  const [sortByField, setSortByField] = React.useState<string>(initialSortByField);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { sort: sortByDirection, field: sortByField }
  ]);

  const [rowCountState, setRowCountState] = React.useState(
    rowCount
  );

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const formattedGridColumns = columns.map((col: any) => ({
    ...col,
    cellClassName: generateCellClass(sortByField),
    headerClassName: generateCellClass(sortByField),
    width: col.width,
    renderCell: renderCell(col.field, linkUrls),
    renderHeader: (params: ColumnMappingType & GridColumnHeaderParams) => {
      if (params.colDef.description && params.colDef.description.length > 0) {
        return (
          <Tooltip title={params.colDef.description} placement="top">
            <strong>{params.colDef.headerName}</strong>
          </Tooltip>
        )
      }
      return (
        <strong>{params.colDef.headerName}</strong>
      )
    },
  }));

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const firstSortModel = newSortModel[0];
    if (firstSortModel) {
      setSortModel(newSortModel);
      const direction = firstSortModel.sort === 'desc' ? '-' : '';
      const ordering = `${direction}${firstSortModel.field}`;
      filterDispatchContext({
        type: 'sortByChanged',
        sortBy: ordering,
      });
      setSortByDirection(firstSortModel.sort);
      setSortByField(firstSortModel.field)
      handleDone(ordering, 0);
    }
  };

  const handlePageChange = (newPaginationModel: GridPaginationModel) => {
    filterDispatchContext({
      type: 'pageByChanged',
      page: newPaginationModel.page,
    });
    handleDone(filterContext.sortBy, newPaginationModel.page);
  };

  return (
    <Box style={{ margin: 5 }}>
      <DataGrid
        columns={formattedGridColumns}
        rows={rows}
        rowCount={rowCountState}
        rowHeight={DEFAULT_ROW_HEIGHT}
        getRowId={(row) => row.rank ?? row.id}
        disableColumnFilter
        disableColumnMenu
        columnHeaderHeight={DEFAULT_ROW_HEIGHT}
        pagination
        paginationMode="server"
        pageSizeOptions={[25]}
        onPaginationModelChange={handlePageChange}
        paginationModel={{ page: (filterContext.page ?? 0), pageSize: DEFAULT_PAGE_SIZE }}
        slots={{
          footer: LastUpdated,
        }}
        sortingOrder={['desc', 'asc']}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(model) => handleSortModelChange(model)}
        sx={(theme) => ({
          [`.${gridClasses.main}`]: {
            overflow: 'unset',
            color: theme.palette.custom?.mediumGrey,
            fontSize: '12px',
          },
          [`.${gridClasses.columnHeaders}`]: {
            position: 'sticky',
            top: 70,
            backgroundColor: theme.palette.background.paper,
            zIndex: 1,
            borderBottom: 'none !important',
          },
          [`.${gridClasses.columnHeaderTitle}`]: {
            textTransform: 'capitalize',
          },
          [`.${gridClasses.columnSeparator}`]: {
            visibility: 'hidden',
            display: 'none',
          },
          [`.${gridClasses.columnHeadersInner}`]: {
            backgroundColor: theme.palette.custom?.lighterGrey,
            borderBottom: '2px solid rgba(224, 224, 224, 1)',
          },
          '& .MuiDataGrid-cell:hover, & .MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.custom?.blueGrey,
          },
          '& .Mui-selected': {
            backgroundColor: 'white !important',
          },
          marginY: '8px',
        })}
        autoHeight
      />
    </Box>
  );
}

export default EliasDataGrid;
