import {
  DEFAULT_ACTIVE_STATUS,
  DEFAULT_GAMETYPE_STATUS,
  DEFAULT_HOME_AWAY,
  DEFAULT_OVERTIME_STATUS,
  DEFAULT_ROOKIE_STATUS,
  activeStatusOptions,
  gameTypeStatusOptions,
  homeAwayOptions,
  overtimeStatusOptions,
  rookieStatusOptions,
} from "../../compounds/FastFilters/constants";
import { DEFAULT_STREAK_RANGE_OPTION } from "../../pages/NcaaWbb/PlayerStreakFinder/constants";
import { DEFAULT_SPAN_RANGE_OPTION } from "../../pages/NcaaWbb/PlayerSpanFinder/constants";
import { StatFilter } from "../../compounds/EliasStatsFilter/filter-stats-utils";
import { ContextAdvancedFiltersType, ContextFilterChildType, ContextFilterType } from "../../types/context";
import { ModalFilterCategoriesType, ModalFilterChildType, ModalFilterType } from "../../types/modal";
import { SelectedStatFilterType } from "../../types/statsFilter";
import {
  ALL_CONFERENCE_TOURNAMENT_IDS,
  ALL_PS_TOURNAMENT_IDS,
  CONF_TOURNAMENTS,
  CONF_TOURNAMENT_ROUNDS,
  CURRENT_SEASON_YEAR,
  NCAA_ROUNDS,
  NCAA_TOURNAMENT_ID,
  POST_SEASON_TOURNAMENTS,
} from "./constants";
import { FINDER_TYPES } from "../common/constants";

type FilterValues = (string | number | boolean)[];
type ApiFilter = {
  name: string;
  values: FilterValues;
};

class ApiFilterContainer {
  filters: Map<string, ApiFilter> = new Map();

  constructor(initialFilters: ApiFilter[] = []) {
    initialFilters.forEach((f: ApiFilter) => {
      this.setFilter(f);
    });
  }

  append({ name, values }: ApiFilter) {
    if (this.filters.has(name)) {
      this.filters.get(name)?.values.push(...values);
    } else {
      this.setFilter({name, values});
    }
  }

  intersect({ name, values }: ApiFilter) {
    if (!this.filters.has(name)) {
      this.setFilter({name, values})
    } else {
      const incoming = new Set(values);
      const base = new Set(
        this.filters.get(name)?.values.filter(
          x => incoming.has(x)
        )
      );
      this.setFilter({name, values: Array.from(base)});
    }
  }

  setFilter({ name, values }: ApiFilter) {
    this.filters.set(name, {name, values} as ApiFilter);
  }

  set(name: string, values: FilterValues) {
    this.setFilter({name, values})
  }

  delete(name: string) {
    this.filters.delete(name);
  }

  importStringFilter(filter: string) {
    if (filter.includes('=')) {
      const [ name, strValues ] = filter.split('=');
      const values: string[] = strValues.split(',');
      this.setFilter({ name, values});
    }
  }

  importStringFilters(filters: string[]) {
    filters.forEach(f => this.importStringFilter(f));
  };

  toStringFilters(): string[] {
    return Array.from(this.filters.values()).map(
      (f: ApiFilter) => `${f.name}=${f.values.join(',')}`
    );
  }

  toString() {
    return this.toStringFilters().join('&');
  }
}

const mapEqualitySymbolToSearchParam = (symbol?: string): string => {
  switch (symbol) {
    case '<=':
      return '__lte=';
    case '>=':
      return '__gte=';
    default:
      return '=';
  }
};

const mapValueToSearchValue = (
  id: string,
  value?: string,
  searchModifierFunction?: (
    value: string,
    modifierValue: number,
  ) => string,
  searchModifierValue?: string,
): string | number => {
  if (!value) return '';
  if (searchModifierFunction) {
    const modValue = Number(searchModifierValue);
    return searchModifierFunction(value, modValue);
  }
  if (id === 'seconds') {
    return Number(value) * 60;
  }
  if (id === 'last_game_result') {
    return value === '1' ? 'W' : 'L';
  };
  return value;
};

const mapIdToSearchId = (id: string) => {
  if (id.includes('_per_game')) {
    return id.replace('_per_game', '');
  }
  return id;
};

export const formatStatsFilterForSearch = (
  filters: SelectedStatFilterType[],
  statsFilterOptions: StatFilter[],
  searchModifierValue?: string,
): ApiFilter[] => {
  if (filters.length === 0) return [];

  let years: number[] = [];
  let searchFilters: ApiFilter[] = filters.filter(
    (filter) => filter.value && filter.value.length > 0
  ).map((filter) => {
    const statFilterOption = statsFilterOptions.find(
      (statFilter) => statFilter.id === filter.id
    );

    if (statFilterOption) {
      const symbol = mapEqualitySymbolToSearchParam(filter.equalitySymbol);
      const formattedValue = mapValueToSearchValue(filter.id, filter.value, statFilterOption.searchModifier, searchModifierValue);
      const formattedId = mapIdToSearchId(filter.id);
      if (statFilterOption.year) {
        years = years.concat(statFilterOption.year);
      }
      return {
        name: `${formattedId}${symbol.slice(0,-1)}`,
        values: [formattedValue]
      };
    }

    return {} as ApiFilter;
  });

  const filterYear = Math.max(...years);
  if (filterYear > 0) {
    searchFilters = searchFilters.concat({name: 'season__gte', values:[filterYear]});
  }

  return searchFilters;
};

const generateGameTypeFilters = (
  filterContext: ContextFilterType,
  filterContainer: ApiFilterContainer,
): ApiFilterContainer => {
  const conferenceTournaments: Set<number> = new Set();
  const postSeasonTournaments: Set<number> = new Set();
  const postseasonTournamentRoundOptions: string[] = [];
  const conferenceTournamentRoundOptions: string[] = [];

  // gather tournament-related checkboxes
  Object.entries(
    filterContext.advancedFilters
  ).filter(
    (pair) => pair[1] === true
  ).forEach(
    (checkboxFilter: [string, any]
  ) => {
    const key = checkboxFilter[0];

    // Post-season tournaments
    if (Object.keys(POST_SEASON_TOURNAMENTS).includes(key)) {
      // Normally, would want to add a check here for NCAA tournament,
      // since it's also in a Fast Filter, but the option was also removed
      // from all modal filters for NCAAW, so it shouldn't be possible for
      // them to be true at the same time.
      const value: number = POST_SEASON_TOURNAMENTS[key];
      postSeasonTournaments.add(value);
    }
    if (Object.keys(NCAA_ROUNDS).includes(key)) {
      const roundValue: string = NCAA_ROUNDS[key];
      postSeasonTournaments.add(NCAA_TOURNAMENT_ID);
      if (!Object.keys(POST_SEASON_TOURNAMENTS).includes(key)) {
        postseasonTournamentRoundOptions.push(roundValue);
      }
    }

    // Conference tournaments
    if (
      Object.keys(CONF_TOURNAMENTS).includes(key)
    ) {
      const value: number = CONF_TOURNAMENTS[key];
      conferenceTournaments.add(value);
    }
    if (
      Object.keys(CONF_TOURNAMENT_ROUNDS).includes(key)
    ) {
      const roundValue: string = CONF_TOURNAMENT_ROUNDS[key];
      conferenceTournamentRoundOptions.push(roundValue);
    }
  });

  // General approach
  // 1. If REG chose, just set REG
  //   a. AND any tournaments or tournament rounds set
  // 2. If Conference game chose, set REG and conference game
  //   a. AND any tournaments or tournament rounds set
  // 3. If non-conference, set REG and non-conference
  //   a. AND any tournaments or tournament rounds set
  // 4. If conference tournament, set all conference tournaments
  //  a. unless specific conference tournaments chosen, then only set those
  // 5. If NCAA Tournament chosen, set that only that
  //   a. AND any tournaments or tournament rounds set

  // Handle collected tournament options
  const postSeasonTournamentIds = Array.from(postSeasonTournaments);
  const conferenceTournamentIds = Array.from(conferenceTournaments);

  if (conferenceTournamentIds.length > 0) {
    filterContainer.append({
      name: 'game__tournament__id__in',
      values: conferenceTournamentIds,
    });
  }

  if (postSeasonTournamentIds.length > 0) {
    filterContainer.append({
      name: 'game__tournament__id__in',
      values: postSeasonTournamentIds,
    });
  }

  if (postseasonTournamentRoundOptions.length > 0) {
    filterContainer.append({
      name: 'game__tournament_round__in',
      values: postseasonTournamentRoundOptions,
    });

    // if no PS tournaments chosen by the user, set them now
    if (postSeasonTournamentIds.length === 0) {
      filterContainer.append({
        name: 'game__tournament__id__in',
        values: ALL_PS_TOURNAMENT_IDS.map(i => `${i}`),
      });
    }
  }

  if (conferenceTournamentRoundOptions.length > 0) {
    filterContainer.append({
      name: 'game__tournament_round__in',
      values: conferenceTournamentRoundOptions,
    });

    // if no conf tournaments chosen by the user, set them now
    if (conferenceTournamentIds.length === 0) {
      filterContainer.append({
        name: 'game__tournament__id__in',
        values: ALL_CONFERENCE_TOURNAMENT_IDS.map(i => `${i}`),
      });
    }
  }
  // AE30-1024: game type fast filter
  const { gameTypeStatus } = filterContext;
  if (
    gameTypeStatus !== DEFAULT_GAMETYPE_STATUS
    && gameTypeStatusOptions.map(o => o.id).includes(gameTypeStatus)
  ) {
    if (gameTypeStatus === 'regular-season') {
      filterContainer.set('game__game_class', ['REG']);
    } else if (gameTypeStatus === 'conference') {
      filterContainer.set('game__game_class', ['REG']);
      filterContainer.set('game__conference_game', [true]);
    } else if (gameTypeStatus === 'non-conference') {
      filterContainer.set('game__game_class', ['REG']);
      filterContainer.set('game__conference_game', [false]);
    } else if (gameTypeStatus === 'conference-tournament') {
      // This is essentially a no-op if any tournament option is selected
      // in the Advanced Modal filters.
      if (conferenceTournamentIds.length === 0) {
        filterContainer.append({
          name: 'game__tournament__id__in',
          values: ALL_CONFERENCE_TOURNAMENT_IDS.map(i => `${i}`),
        });
      }
    } else if (gameTypeStatus === 'ncaa-tournament') {
      filterContainer.append({
        name: 'game__tournament__id__in',
        values: [1],
      });
    }
  }

  return filterContainer;
}

const getApRankLookup = (
  equalitySymbol: string, 
  finderType?: string, 
  filterChildTypes?: ContextFilterChildType[], 
  childId?: string,
  modalFilterType?: ModalFilterType,
): string => {
  // EN-3936 - 1/10/2025
  let limitQueryset = '';

  // Initially only for streaks.  This is defined in src/components/pages/NcaaMbb/constants.ts and src/components/pages/NcaaWbb/constants.ts.
  if (filterChildTypes) {
    const modalFilterChildType: ModalFilterChildType | undefined = modalFilterType?.childTypes?.find(ct => ct.id === childId);

    if (modalFilterChildType?.finderTypes && finderType && modalFilterChildType.finderTypes.indexOf(finderType) >= 0) {
      limitQueryset = filterChildTypes.find(fct => fct.id === childId)?.selected ? '' : '_only_limit_queryset';
    }    
  }
  // franchise_rank__gte_or_null_only_limit_queryset, opponent_rank__gte_or_null_only_limit_queryset
  const defaultLookup = equalitySymbol === '__gte' ? `__gte_or_null${limitQueryset}` : `${equalitySymbol}${limitQueryset}`;  

  if (finderType) {
    switch (finderType) {
      case FINDER_TYPES.PLAYER_SPAN:
      case FINDER_TYPES.TEAM_SPAN:
        // Found while working on EN-3936.
        // Did not appear that the = and <= rank fields were being included in the span queries.
        // Could not figure out why so for now passing in these fields with _2 and creating the filters manually.
        // Leaving >= as __gte_or_null.  From other existing code, this would not be automatically mapped anyway since both >= and "or null" is needed.
        // franchise_rank_2, franchise_rank_2__lte, franchise_rank__gte_or_null
        // opponent_rank_2, opponent_rank_2__lte, opponent_rank__gte_or_null
        if (equalitySymbol === '__gte') {
          return `__gte_or_null${limitQueryset}`;
        }
        
        return `_2${equalitySymbol}${limitQueryset}`
        
      default:
        return defaultLookup;
    }
  }
  else {
    return defaultLookup;
  }  
};

const formatAdvancedFilters = (
  advancedFilterContext: ContextAdvancedFiltersType,
  filterContainer: ApiFilterContainer,
  finderType?: string,
  modalFilterCategories?: ModalFilterCategoriesType[],
): ApiFilterContainer => {

  const { player_age: playerAge } = advancedFilterContext;
  if (playerAge.selected && playerAge.value) {
    const searchEquality = mapEqualitySymbolToSearchParam(playerAge.equalityOption);
    filterContainer.append({
      name: `age${searchEquality.slice(0, -1)}`,
      values: [playerAge.value],
    });
  }

  const { team_ap_rank: teamApRank } = advancedFilterContext;
  if (teamApRank.selected && teamApRank.value) {
    const searchEquality = mapEqualitySymbolToSearchParam(teamApRank.equalityOption);
    const modalFilterCategory: ModalFilterType | undefined = modalFilterCategories?.find(fc => fc.category === 'ap_rank')?.filters.find(f => f.id === 'team_ap_rank')
    const lookup = getApRankLookup(searchEquality.slice(0, -1), finderType, teamApRank.filterChildTypes, 'team_ap_rank_child_01', modalFilterCategory);
    filterContainer.append({
      name: `franchise_rank${lookup}`,
      values: [teamApRank.value],
    });
  }

  const { opponent_ap_rank: OpponentApRank } = advancedFilterContext;
  if (OpponentApRank.selected && OpponentApRank.value) {
    const searchEquality = mapEqualitySymbolToSearchParam(OpponentApRank.equalityOption);
    const modalFilterCategory: ModalFilterType | undefined = modalFilterCategories?.find(fc => fc.category === 'ap_rank')?.filters.find(f => f.id === 'opponent_ap_rank')
    const lookup = getApRankLookup(searchEquality.slice(0, -1), finderType, OpponentApRank.filterChildTypes, 'opponent_ap_rank_child_01', modalFilterCategory);
    filterContainer.append({
      name: `opponent_rank${lookup}`,
      values: [OpponentApRank.value],
    });
  }

  const { team_seed: teamSeed } = advancedFilterContext;
  if (teamSeed.selected && teamSeed.value) {
    const searchEquality = mapEqualitySymbolToSearchParam(teamSeed.equalityOption);
    filterContainer.append({
      name: `franchise_tournament_seed${searchEquality.slice(0, -1)}`,
      values: [teamSeed.value],
    });
  }

  const { opponent_seed: opponentSeed } = advancedFilterContext;
  if (opponentSeed.selected && opponentSeed.value) {
    const searchEquality = mapEqualitySymbolToSearchParam(opponentSeed.equalityOption);
    filterContainer.append({
      name: `opponent_tournament_seed${searchEquality.slice(0, -1)}`,
      values: [opponentSeed.value],
    });
  }

  const { seasons } = advancedFilterContext;
  if (seasons.selected && seasons.value) {
    const searchEquality = mapEqualitySymbolToSearchParam(seasons.equalityOption);
    filterContainer.append({
      name: `seasons${searchEquality.slice(0, -1)}`,
      values: [seasons.value],
    });
  }

  const { season_group: seasonGroup } = advancedFilterContext;
  if (seasonGroup.length > 0) {
    const {
      season_group_value: seasonGroupValue,
      season_group_end_value: seasonGroupEndValue,
    } = advancedFilterContext;
    switch (seasonGroup) {
      case 'season_range': {
        const formattedStart = seasonGroupValue.split('-')[0];
        const formattedEnd = seasonGroupEndValue.split('-')[0];
        filterContainer.append({
          name: 'season__gte', values: [formattedStart]
        });
        filterContainer.append({
          name: 'season__lte', values: [formattedEnd]
        });
        break;
      }
      case 'current_season':
        filterContainer.append({
          name: 'season', values: [CURRENT_SEASON_YEAR],
        });
        break;
      default:
        break;
    }
  }

  Object.entries(
    advancedFilterContext
  ).filter(
    (pair) => pair[1] === true
  ).forEach((checkboxFilter: [string, any]) => {
    const filterName = checkboxFilter[0];

    // Do not act on CONF_TOURNAMENTS or POST_SEASON_TOURNAMENTS checkboxes
    // as they hare handled by the call to generateTournamentRoundFilters
    // below.
    if (!Object.keys(CONF_TOURNAMENTS).includes(filterName)
      && !Object.keys(POST_SEASON_TOURNAMENTS).includes(filterName)
      && !Object.keys(NCAA_ROUNDS).includes(filterName)
      && !Object.keys(CONF_TOURNAMENT_ROUNDS).includes(filterName)
    ) {
      filterContainer.append({
        name: filterName, values: [1],
      });
    }
  });

  return filterContainer;
};

export const appendContextFilters = (
  localFilters: string[],
  context: ContextFilterType,
  statsFilterOptions: StatFilter[],
  searchModifierValue?: string,
  finderType?: string,
  modalFilterCategories?: ModalFilterCategoriesType[],
) => {
  const container = new ApiFilterContainer();
  container.importStringFilters(localFilters);

  // Handle Game Type fast filter/Advanced modal tournament options early
  generateGameTypeFilters(context, container);

  const { selectedStatsFilters } = context;
  if (selectedStatsFilters && selectedStatsFilters.length > 0) {
    const statFilters = formatStatsFilterForSearch(
        selectedStatsFilters,
        statsFilterOptions,
        searchModifierValue,
      )
    statFilters.forEach((f: ApiFilter) => { container.append(f); });
  }

  // Modal filters
  formatAdvancedFilters(context.advancedFilters, container, finderType, modalFilterCategories);

  // Boolean filters
  const { activeStatus } = context;
  if (
    activeStatus !== DEFAULT_ACTIVE_STATUS
    && activeStatusOptions.map(o => o.id).includes(activeStatus)
  ) {
    container.append({
      name: 'player__active',
      values: [activeStatus === 'active']
    });
  }

  const { homeAway } = context;
  if (
    homeAway !== DEFAULT_HOME_AWAY
    && homeAwayOptions.map(o => o.id).includes(homeAway)
  ) {
    let ncaaHomeAwayFilter = '';
    if (homeAway === 'home') ncaaHomeAwayFilter = 'H';
    else if (homeAway === 'away') ncaaHomeAwayFilter = 'R';
    else if (homeAway === 'neutral') ncaaHomeAwayFilter = 'N';
    container.append({name: 'home_game', values:[ncaaHomeAwayFilter]});
  }

  const { rookieStatus } = context;
  if (
    rookieStatus !== DEFAULT_ROOKIE_STATUS
    && rookieStatusOptions.map(o => o.id).includes(rookieStatus)
  ) {
    container.append({
      name: 'rookie',
      values:[rookieStatus === 'rookie']
    });
  }

  const { overtimeStatus } = context;
  if (
    overtimeStatus !== DEFAULT_OVERTIME_STATUS
    && overtimeStatusOptions.map(o => o.id).includes(overtimeStatus)
  ) {
    container.append({
      name:'game__overtime_played',
      values: [context.overtimeStatus === 'overtime'],
    });
  }

  // Enumerated filters
  const { playerObject } = context;
  if (playerObject) {
    container.append({
      name: 'player__id',
      values: [playerObject.id]
    });
  }

  const { opposingPlayerObject } = context;
  if (opposingPlayerObject) {
    container.append({
      name: 'played_against',
      values: [opposingPlayerObject.id]
    });
  }

  const { playerTeammates } = context;
  if (playerTeammates && playerTeammates.length > 0) {
    const ids = context.playerTeammates.map((option) => option.id);
    container.append({name: 'played_with', values: [...ids]});
  }

  const { teamObject } = context;
  if (teamObject) {
    container.append({
      name: 'franchise_season__franchise_id',
      values: [teamObject.franchise_id],
    });
  }

  const { venueObject } = context;
  if (venueObject) {
    container.append({
      name: 'game__venue',
      values: [venueObject.venue],
    });
  }

  const { opponentObject } = context;
  if (opponentObject) {
    container.append({
      name: 'opponent_season__franchise_id',
      values: [opponentObject.franchise_id],
    });
  }

  const { conferenceObject } = context;
  if (conferenceObject) {
    container.append({
      name: 'franchise_conference_id',
      values: [conferenceObject.conference_id],
    });
  }

  const { opponentConferenceObject } = context;
  if (opponentConferenceObject) {
    container.append({
      name: 'opponent_conference_id',
      values: [opponentConferenceObject.conference_id],
    });
  }

  const { streakGameNumber } = context;
  if (streakGameNumber) {
    container.append({name: 'streak_length', values: [streakGameNumber]});
  }

  const { streakRangeOption } = context;
  if (streakRangeOption && streakRangeOption !== DEFAULT_STREAK_RANGE_OPTION) {
    container.append({name: 'streak_range', values: [streakRangeOption]});
  }

  const { spanLength } = context;
  if (spanLength) {
    container.append({name: 'span_length', values: [spanLength]});
  }


  const { spanRangeOption } = context;
  if (spanRangeOption && spanRangeOption !== DEFAULT_SPAN_RANGE_OPTION) {
    container.append({name: 'span_range', values: [spanRangeOption]});
  }

  const { groupBy } = context;
  if (groupBy && groupBy !== 'none') {
    container.append({name: 'group_by', values: [groupBy]});
  };

  return container.toStringFilters();
};

export const searchEnabled = (
  context: ContextFilterType,
  pageSpecificSearchEnabled: (context: ContextFilterType) => boolean,
  // eslint-disable-next-line arrow-body-style
): boolean => {
  return context.searchEnabled && pageSpecificSearchEnabled(context) && (
    // stats filters selected
    context.selectedStatsFilters.length > 0 ||

    // fast filters selected
    (context.activeStatus !== DEFAULT_ACTIVE_STATUS && context.activeStatus.length > 0) ||
    (context.rookieStatus !== DEFAULT_ROOKIE_STATUS && context.rookieStatus.length > 0) ||
    (context.homeAway !== DEFAULT_HOME_AWAY && context.homeAway.length > 0) ||
    (context.overtimeStatus !== DEFAULT_OVERTIME_STATUS && context.overtimeStatus.length > 0) ||

    // autocomplete filter selected
    Boolean(context.playerObject && context.playerObject.id) ||
    Boolean(context.opposingPlayerObject && context.opposingPlayerObject.id) ||
    Boolean(context.playerTeammates && context.playerTeammates.length > 0) ||
    Boolean(context.venueObject && context.venueObject.venue) ||
    Boolean(context.teamObject && context.teamObject.id) ||
    Boolean(context.opponentObject && context.opponentObject.id) ||

    // modal filters
    context.gameTypeFilters.holiday_group?.length > 0 ||
    context.gameTypeFilters.postseason_group?.length > 0 ||
    context.gameTypeFilters.season_group?.length > 0 ||
    context.gameTypeFilters.pin || context.gameTypeFilters.sem || context.gameTypeFilters.rd1 ||
    context.gameTypeFilters.as || context.gameTypeFilters.fin || context.gameTypeFilters.con
  );
};
