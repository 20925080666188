import { AE2_URL, SPORT_LEAGUES } from '../common/constants';

export const BASE_NBA_URL = `${AE2_URL}/${SPORT_LEAGUES.NBA}/`;
export const BASE_WNBA_URL = `${AE2_URL}/${SPORT_LEAGUES.WNBA}/`;

export type ExternalLeagueRoutesType = {
  playerProfile: string;
  franchiseProfile: string;
  boxscore: string;
};

const EXTERNAL_URLS = {
  NBA: {
    playerProfile: `${BASE_NBA_URL}Player/Profile/:playerId#`,
    franchiseProfile: `${BASE_NBA_URL}Team/Profile/:teamId#`,
    boxscore: `${BASE_NBA_URL}boxscore/game/:gameId`,
  },
  WNBA: {
    playerProfile: `${BASE_WNBA_URL}Player/Profile/:playerId#`,
    franchiseProfile: `${BASE_WNBA_URL}Team/Profile/:teamId#`,
    boxscore: `${BASE_WNBA_URL}boxscore/game/:gameId`,
  },
  NCAAM: {
    playerProfile: '/ncaambb/player/:playerId',
    franchiseProfile: '/ncaambb/team/:teamId',
    boxscore: '/ncaambb/game/:gameId',
  },
  NCAAW: {
    playerProfile: '/ncaawbb/player/:playerId',
    franchiseProfile: '/ncaawbb/team/:teamId',
    boxscore: '/ncaawbb/game/:gameId',
  },
};

export default EXTERNAL_URLS;
