import React from 'react';
import { generatePath } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import BoxScore from './BoxScore';
import GamePlayerStatsTable from './GamePlayerStatsTable';
import { LeagueType, useLeagueContext } from '../../contexts/LeagueContext';
// import RecentGames from './RecentGames';
import { GameBoxScorePlayerStatsType, GameBoxScoreTeamOverviewType, GameBoxScoreType } from '../../types/game';
import ROUTER_URLS from '../../utils/router/urls';
import StyledPaper from '../../atoms/StyledPaper/StyledPaper';
import { formatTournamentNameDisplay, formatRoundNameDisplay } from '../../utils/common/common';
import { getTeamLogoUrl } from '../../utils/common/teams';

interface ScoringType {
  total: number;
  first_half: number;
  second_half: number;
  overtime_total?: number | undefined;
}

interface IProps {
  gameData: GameBoxScoreType;
}

const mapPlayerGameData = (selectedLeague: LeagueType, data: GameBoxScorePlayerStatsType[]) =>
  data.map((row) => {
    const url = ROUTER_URLS[selectedLeague].player;
    const playerLink = (
      <a href={generatePath(url, { playerId: row.player_id })}>{row.name}</a>
    );
    return {
      playerId: row.player_id,
      playerName: row.name,
      playerLink,
      minutes: row.stats.minutes,
      fieldGoals: row.stats.field_goals_made,
      fieldGoalAttempts: row.stats.field_goal_attempts,
      threePointFgs: row.stats.three_point_field_goals_made,
      threePointFgas: row.stats.three_point_field_goal_attempts,
      freeThrows: row.stats.free_throws_made,
      freeThrowAttempts: row.stats.free_throw_attempts,
      offensiveRebounds: row.stats.offensive_rebounds,
      defensiveRebounds: row.stats.defensive_rebounds,
      rebounds: row.stats.total_rebounds,
      assists: row.stats.assists,
      steals: row.stats.steals,
      blocks: row.stats.blocks,
      turnovers: row.stats.turnovers,
      personalFouls: row.stats.personal_fouls,
      points: row.stats.points,
      started: row.started,
    }
  });

const formattedTeamRecord = (teamWins: number = 0, teamLosses: number = 0, conferenceWins: number = 0, conferenceLosses: number = 0, conferenceAbbr: string = '') => (
  `${teamWins}-${teamLosses}, ${conferenceWins}-${conferenceLosses} ${conferenceAbbr}`
);

const getTeamLink = (league: LeagueType, team?: GameBoxScoreTeamOverviewType) => {
  if ((league === 'ncaambb' || league === 'ncaawbb') && team?.conference_id !== 10000) {
    return generatePath(ROUTER_URLS[league].team, { teamId: team?.franchise_id });
  }

  return '';
};

const mapBoxScoreData = (data: GameBoxScoreType, selectedLeague: LeagueType) => {
  const homeTeamLink = getTeamLink(selectedLeague, data.overview.home);
  const visitorTeamLink = getTeamLink(selectedLeague, data.overview.visitor);

  const venueState = data.overview.venue.state ? `, ${data.overview.venue.state}` : '';
  const venue = data.overview.venue && data.overview.venue.name && data.overview.venue.name.length > 0 ?
    `${data.overview.venue.name} ${data.overview.venue.city}${venueState}` : '';
  const date = `${data.date}`;

  const homeLogoUrl = getTeamLogoUrl({ 
    league: selectedLeague,
    franchiseId: data.overview.home?.conference_id !== 10000 ? data.overview.home?.franchise_id : undefined,
  });
  const visitorLogoUrl = getTeamLogoUrl({ 
    league: selectedLeague,
    franchiseId: data.overview.visitor?.conference_id !== 10000 ? data.overview.visitor?.franchise_id : undefined,
  });

  let numberOfOvertimes = 0;
  if (data.overview.home?.scoring) {
    const keys = Object.keys(data.overview.home?.scoring);
    // find all keys in the scoring object that have overtime in their name and do not have null values
    // remove 1 because we also sent the overtime total
    numberOfOvertimes = keys.filter((key) => {
      const scoring = data.overview.home?.scoring;
      const value: any = scoring ? scoring[key as keyof ScoringType] : 0;
      return key.includes('overtime') && Boolean(value)
    }).length - 1;
  }

  const isPostSeason = data.game_class === 'PS'

  const homeRank = isPostSeason ?
    data.overview.home?.franchise_tournament_seed
    : data.overview.home?.franchise_rank ;
  const visitorRank = isPostSeason ?
    data.overview.visitor?.franchise_tournament_seed
    : data.overview.visitor?.franchise_rank;

  const rankType = isPostSeason ? 'Seed' : 'AP';

  const home = {
    teamName: data.overview.home?.short_name || '',
    teamRecord: formattedTeamRecord(
      data.overview.home?.franchise_wins,
      data.overview.home?.franchise_losses,
      data.overview.home?.franchise_conference_wins,
      data.overview.home?.franchise_conference_losses,
      data.overview.home?.franchise_conference_abbr,
    ),
    teamScore: Number(data.overview.home?.score),
    teamWin: Number(data.overview.home?.score) > Number(data.overview.visitor?.score),
    teamLink: homeTeamLink,
    teamLogoUrl: homeLogoUrl,
    coachName: data.overview.home?.head_coach || '',
    gameStats: mapPlayerGameData(selectedLeague, data.overview.home?.game_stats || []),
    firstHalfScore: data.overview.home?.scoring?.first_half,
    secondHalfScore: data.overview.home?.scoring?.second_half,
    overtime1Score: data.overview.home?.scoring?.overtime_1,
    overtime2Score: data.overview.home?.scoring?.overtime_2,
    overtime3Score: data.overview.home?.scoring?.overtime_3,
    overtime4Score: data.overview.home?.scoring?.overtime_4,
    overtime5Score: data.overview.home?.scoring?.overtime_5,
    overtime6Score: data.overview.home?.scoring?.overtime_6,
    overtimeScore: Number(data.overview.home?.scoring?.overtime_total),
    quarter1Score: data.overview.home?.scoring?.quarter_1,
    quarter2Score: data.overview.home?.scoring?.quarter_2,
    quarter3Score: data.overview.home?.scoring?.quarter_3,
    quarter4Score: data.overview.home?.scoring?.quarter_4,
    rank: homeRank,
    rankType,
  };

  const visitor = {
    teamName: data.overview.visitor?.short_name || '',
    teamRecord: formattedTeamRecord(
      data.overview.visitor?.franchise_wins,
      data.overview.visitor?.franchise_losses,
      data.overview.visitor?.franchise_conference_wins,
      data.overview.visitor?.franchise_conference_losses,
      data.overview.visitor?.franchise_conference_abbr,
    ),
    teamScore: Number(data.overview.visitor?.score),
    teamWin: Number(data.overview.visitor?.score) > Number(data.overview.home?.score),
    teamLink: visitorTeamLink,
    teamLogoUrl: visitorLogoUrl,
    coachName: data.overview.visitor?.head_coach || '',
    gameStats: mapPlayerGameData(selectedLeague, data.overview.visitor?.game_stats || []),
    firstHalfScore: data.overview.visitor?.scoring?.first_half,
    secondHalfScore: data.overview.visitor?.scoring?.second_half,
    overtime1Score: data.overview.visitor?.scoring?.overtime_1,
    overtime2Score: data.overview.visitor?.scoring?.overtime_2,
    overtime3Score: data.overview.visitor?.scoring?.overtime_3,
    overtime4Score: data.overview.visitor?.scoring?.overtime_4,
    overtime5Score: data.overview.visitor?.scoring?.overtime_5,
    overtime6Score: data.overview.visitor?.scoring?.overtime_6,
    overtimeScore: Number(data.overview.visitor?.scoring?.overtime_total),
    quarter1Score: data.overview.visitor?.scoring?.quarter_1,
    quarter2Score: data.overview.visitor?.scoring?.quarter_2,
    quarter3Score: data.overview.visitor?.scoring?.quarter_3,
    quarter4Score: data.overview.visitor?.scoring?.quarter_4,
    rank: visitorRank,
    rankType,
  };

  let tournamentName: string = '';
  let tournamentRound: string = '';

  if (data.tournament?.tournament?.post_season) {
    tournamentName = formatTournamentNameDisplay(selectedLeague, data.tournament.tournament.post_season.id, data.tournament.tournament.post_season.name);
    tournamentRound = formatRoundNameDisplay(selectedLeague, data.tournament.tournament.post_season.id, data.tournament.tournament.post_season.round);
  }

  return {
    venue,
    date,
    numberOfOvertimes,
    home,
    visitor,
    tournamentName,
    tournamentRound,
  };
};

function GameLayout({ gameData }: IProps) {
  const leagueContext: any = useLeagueContext();
  const boxScoreData = mapBoxScoreData(gameData, leagueContext.selectedLeague);

  return (
    <Grid container direction='row' spacing={3} marginY={5} marginX={4}>
      <Grid item xs={9}>
        <Grid container direction="column">
          <Grid item>
            <StyledPaper variant='outlined'>
              <BoxScore data={boxScoreData} />
            </StyledPaper>
          </Grid>
          <Grid item>
            <GamePlayerStatsTable
              teamName={boxScoreData.visitor.teamName}
              coachName={boxScoreData.visitor.coachName}
              data={boxScoreData.visitor.gameStats}
            />
          </Grid>
          <Grid item>
            <GamePlayerStatsTable
              teamName={boxScoreData.home.teamName}
              coachName={boxScoreData.home.coachName}
              data={boxScoreData.home.gameStats}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
      {
      //  <Grid container direction="column">
      //    <Grid item>
      //      <StyledPaper elevation={2}>
      //        <RecentGames />
      //      </StyledPaper>
      //    </Grid>
      //  </Grid>
      }
      </Grid>
    </Grid>
  );
}

export default GameLayout;
