import React from 'react';
import TeamGameTable from '../../../compounds/TeamGameTable/TeamGameTable';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { fetchNbaComebackStats } from '../../../../api/nba';
import { statsFilters, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories, comebacksModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NbaTeamFiltersLayout from '../../../compounds/nba/NbaTeamFiltersLayout';
import { mapComebacksRows } from '../../../utils/nba/game-finder/map-results';
import { mapComebacksColumns } from '../../../utils/nba/game-finder/map-columns';
import withResultsLayout, { WithResultsLayoutProps } from '../../../compounds/ResultsWrapper/ResultsWrapper';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/nba/search';
import Header from '../../../compounds/Common/Header';
import EXTERNAL_URLS from '../../../utils/router/external_urls';

interface IProps extends WithResultsLayoutProps {};

function ComebacksFinderResults({
  columnHeaders,
  resultsData,
  tableDataLoading,
  filtersLoading,
  error,
  handleDone,
  pageOrSortDataFetch
}: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo />;
  const groupByPartial = null;
  const gameTypeModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={comebacksModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const advancedModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
      comingSoon
    />
  );

  return (
    <Dashboard loading={filtersLoading}>
      <FinderStackLayout
        header={
          <Header
            title={PAGE_TITLE}
            subtitle={PAGE_SUBTITLE}
            backButton={
              <EliasBackButton route={ROUTER_URLS.nba.comebacksFinderSearch} />
            }
          />
        }
        topPanel={
          <NbaTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.nba}
            topModalPartial={gameTypeModalPartial}
            bottomModalPartial={advancedModalPartial}
            groupByPartial={groupByPartial}
            showHalfQuarter
          />
        }
        bottomPanel={
          tableDataLoading || error ?
          <ResultsLoading error={error} /> :
          <TeamGameTable
            data={resultsData?.results ?? []}
            dataCount={resultsData?.count ?? 0}
            dataMetaInfo={resultsData?.query?.back_to ?? []}
            columnData={columnHeaders || []}
            handleDone={pageOrSortDataFetch}
            linkUrls={EXTERNAL_URLS.NBA}
          />
        }
      />
    </Dashboard>
  );
}

export default withResultsLayout(
  ComebacksFinderResults,
  ROUTER_URLS.nba.comebacksFinderResults,
  statsFilters,
  fetchNbaComebackStats,
  mapComebacksColumns,
  mapComebacksRows,
  appendContextFilters,
);
