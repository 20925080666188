import React from 'react';
import axios from 'axios';
import { generatePath, useNavigate } from "react-router-dom"
import API_URLS from '../../../api/api_urls';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { StatFilter } from '../EliasStatsFilter/filter-stats-utils';
import { ContextFilterType } from '../../types/context';
import { ModalFilterCategoriesType } from '../../types/modal';

export interface WithCopyableUrlProps {
  handleDone: () => void;
};

function withCopyableUrl<T>(
  WrappedComponent: React.ComponentType<T & WithCopyableUrlProps>,
  navigateTo: string,
  statsFilters: StatFilter[],
  appendContextFilters: (
    localFilters: string[],
    context: ContextFilterType,
    statsFilterOptions: StatFilter[],
    searchModifierValue?: string | undefined,
    finderType?: string,
    modalFilterCategories?: ModalFilterCategoriesType[],
  ) => string[],
  finderType?: string,
  modalFilterCategories?: ModalFilterCategoriesType[],
) {

  function ComponentWithProps(props: T) {
    const navigate = useNavigate();
    const filterContext: any = useFilterContext();
    const filterDispatchContext: any = useFilterDispatchContext();

    const searchSuccessNavigate = (id: string, path: string) => {
      const route = generatePath(path, { searchId: id });
      navigate(route, {
        state: { loadFiltersFromState: true },
        replace: true,
      });
    };

    // updating the context happens async but there isnt a great way to listen or await changes
    // there. get around this but passing the context object directly
    const saveSearchAndNavigate = (
      passedFilterContext: any,
      queryString: string,
    ) => {
      axios.post(API_URLS.search, {
        context: {
          passedFilterContext,
        },
        query_string: queryString,
      }).then((response) => {
        const newId = response.data.id;
        searchSuccessNavigate(newId, navigateTo);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
    };

    const handleDone = () => {
      filterDispatchContext({
        type: 'changeSearchStatus',
        searchEnabled: false,
      });
      const filters: string[] = [
        `ordering=${filterContext.sortBy}`,
      ];
      const combinedFilters = appendContextFilters(filters, filterContext, statsFilters, filterContext.spanLength, finderType, modalFilterCategories);
      const queryString = combinedFilters.join('&')
      saveSearchAndNavigate(filterContext, queryString);
    };

    return (
      <WrappedComponent
        { ...props }
        handleDone={handleDone}
      />
    )
  };
  return ComponentWithProps;
};

export default withCopyableUrl;
