import React from 'react';
import ROUTER_URLS from './urls';
import PrivateRoute from './PrivateRoute';
import PageTitle from '../../atoms/PageTitle/PageTitle';
import NcaaWbbPlayerStreakFinderResults from '../../pages/NcaaWbb/PlayerStreakFinder/PlayerStreakFinderResults';
import NcaaWbbPlayerStreakFinderSearch from '../../pages/NcaaWbb/PlayerStreakFinder/PlayerStreakFinderSearch';
import NcaaWbbPlayerGameFinderResults from '../../pages/NcaaWbb/PlayerGameFinder/PlayerGameFinderResults';
import NcaaWbbPlayerGameFinderSearch from '../../pages/NcaaWbb/PlayerGameFinder/PlayerGameFinderSearch';
import NcaaWbbPlayerSpanFinderSearch from '../../pages/NcaaWbb/PlayerSpanFinder/PlayerSpanFinderSearch';
import NcaaWbbPlayerSpanFinderResults from '../../pages/NcaaWbb/PlayerSpanFinder/PlayerSpanFinderResults';
import NcaaWbbTeamGameFinderResults from '../../pages/NcaaWbb/TeamGameFinder/TeamGameFinderResults';
import NcaaWbbTeamGameFinderSearch from '../../pages/NcaaWbb/TeamGameFinder/TeamGameFinderSearch';
import NcaaWbbTeamSpanFinderResults from '../../pages/NcaaWbb/TeamSpanFinder/TeamSpanFinderResults';
import NcaaWbbTeamSpanFinderSearch from '../../pages/NcaaWbb/TeamSpanFinder/TeamSpanFinderSearch';
import NcaaWbbTeamStreakFinderResults from '../../pages/NcaaWbb/TeamStreakFinder/TeamStreakFinderResults';
import NcaaWbbTeamStreakFinderSearch from '../../pages/NcaaWbb/TeamStreakFinder/TeamStreakFinderSearch';
import NcaaWbbTeam from '../../pages/NcaaWbb/Team/Team';
import NcaaWbbPlayer from '../../pages/NcaaWbb/Player/Player';
import NcaaWbbGame from '../../pages/NcaaWbb/Game/Game';
import NcaaWbbTeamDataInfoPage from '../../pages/NcaaWbb/TeamDataInfo/NcaaWbbTeamDataInfoPage';

import { PAGE_TITLE as NCAAW_PLAYER_GAME_TITLE } from '../../pages/NcaaWbb/PlayerGameFinder/constants';
import { PAGE_TITLE as NCAAW_PLAYER_STREAK_TITLE } from '../../pages/NcaaWbb/PlayerStreakFinder/constants';
import { PAGE_TITLE as NCAAW_PLAYER_SPAN_TITLE } from '../../pages/NcaaWbb/PlayerSpanFinder/constants';
import { PAGE_TITLE as NCAAW_TEAM_GAME_TITLE } from '../../pages/NcaaWbb/TeamGameFinder/constants';
import { PAGE_TITLE as NCAAW_TEAM_STREAK_TITLE } from '../../pages/NcaaWbb/TeamStreakFinder/constants';
import { PAGE_TITLE as NCAAW_TEAM_SPAN_TITLE } from '../../pages/NcaaWbb/TeamSpanFinder/constants';

import {
  NcaaWbbPlayerGameFinderContextProvider,
  NcaaWbbPlayerSpanFinderContextProvider,
  NcaaWbbPlayerStreakFinderContextProvider,
  NcaaWbbTeamGameFinderContextProvider,
  NcaaWbbTeamSpanFinderContextProvider,
  NcaaWbbTeamStreakFinderContextProvider,
} from '../../contexts/FilterContext';


const NCAAW_ROUTES = [
  {
    element: <NcaaWbbPlayerGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaawbb.playerGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_PLAYER_GAME_TITLE} />
            <NcaaWbbPlayerGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaawbb.playerGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_PLAYER_GAME_TITLE} />
            <NcaaWbbPlayerGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaWbbPlayerStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaawbb.playerStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_PLAYER_STREAK_TITLE} />
            <NcaaWbbPlayerStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaawbb.playerStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_PLAYER_STREAK_TITLE} />
            <NcaaWbbPlayerStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaWbbPlayerSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaawbb.playerSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_PLAYER_SPAN_TITLE} />
            <NcaaWbbPlayerSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaawbb.playerSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_PLAYER_SPAN_TITLE} />
            <NcaaWbbPlayerSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <NcaaWbbTeamGameFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaawbb.teamGameFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_TEAM_GAME_TITLE} />
            <NcaaWbbTeamGameFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaawbb.teamGameFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_TEAM_GAME_TITLE} />
            <NcaaWbbTeamGameFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaWbbTeamSpanFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaawbb.teamSpanFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_TEAM_SPAN_TITLE} />
            <NcaaWbbTeamSpanFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaawbb.teamSpanFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_TEAM_SPAN_TITLE} />
            <NcaaWbbTeamSpanFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    element: <NcaaWbbTeamStreakFinderContextProvider />,
    children: [
      {
        path: ROUTER_URLS.ncaawbb.teamStreakFinderSearch,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_TEAM_STREAK_TITLE} />
            <NcaaWbbTeamStreakFinderSearch />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTER_URLS.ncaawbb.teamStreakFinderResults,
        element: (
          <PrivateRoute loginPath={ ROUTER_URLS.login }>
            <PageTitle title={NCAAW_TEAM_STREAK_TITLE} />
            <NcaaWbbTeamStreakFinderResults />
          </PrivateRoute>
        ),
      },
    ]
  },
  {
    path: ROUTER_URLS.ncaawbb.team,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaWbbTeam />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaawbb.player,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaWbbPlayer />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaawbb.game,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <NcaaWbbGame />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaawbb.dataInfo,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <PageTitle title="NCAA Women's Player Data Info" />
        <NcaaWbbTeamDataInfoPage />
      </PrivateRoute>
    )
  },
  {
    path: ROUTER_URLS.ncaawbb.teamDataInfo,
    element: (
      <PrivateRoute loginPath={ ROUTER_URLS.login }>
        <PageTitle title="NCAA Women's Team Data Info" />
        <NcaaWbbTeamDataInfoPage />
      </PrivateRoute>
    )
  },
];

export default NCAAW_ROUTES;
