import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import './EliasInput.scss';
import { InputStyles } from './StyleOverrides';
import {
  EliasInputProps,
  handleInputChange,
  handleInputFocus,
} from './elias-input-utils';

function EliasNumericInput({
  id,
  value,
  onChange,
  ariaLabel,
  isInputValid,
  placeholder = undefined,
}: EliasInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  // Prevent the mouse wheel from incrementing/decrementing the input value
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <TextField
      onFocus={handleInputFocus}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        'aria-label': ariaLabel,
        'data-testid': `${id}-value-input`,
      }}
      inputRef={ inputRef }
      size="small"
      type="number"
      value={value}
      placeholder={placeholder}
      onChange={(event) => handleInputChange(event, onChange)}
      sx={InputStyles}
      error={isInputValid === undefined ? false : !isInputValid}
    />
  );
}

export default EliasNumericInput;
