const API_BASE = '/api/v1';
const AUTH_BASE = `${API_BASE}/auth`;

export interface IApiUrlFinders {
  boxscore: string;
  conference_manifest: string;
  franchise_history: string;
  franchise_profile: string;
  franchise_season_manifest: string;
  franchise_season_profile: string;
  player_game_finder: string;
  player_span_finder: string;
  player_streak_finder: string;
  players: string;
  players_manifest: string;
  player_detail: string;
  player_stats: string;
  team_game_finder: string;
  team_streak_finder: string;
  team_span_finder: string;
  venue_manifest: string;
}

export interface IApiUrlFindersNBA extends IApiUrlFinders {
  team_comebacks_finder: string;
}

interface IApiUrls {
  adf: {
    lastRun: string;
  };
  auth: {
    login: string;
    logout: string;
    refreshToken: string;
    user: string;
    changePassword: string;
  };
  nba: IApiUrlFindersNBA;
  ncaambb: IApiUrlFinders;
  ncaawbb: IApiUrlFinders;
  search: string;
  wnba: IApiUrlFinders;
}

const API_NBA_BASE = `${API_BASE}/nba`;
const API_WNBA_BASE = `${API_BASE}/basketball/wnba`;
const API_NCAAMBB_BASE = `${API_BASE}/basketball/ncaam`;
const API_NCAAWBB_BASE = `${API_BASE}/basketball/ncaaw`;
const API_URLS: IApiUrls = {
  adf: {
    lastRun: '/api/v1/adf/last-run/',
  },
  auth: {
    login: `${AUTH_BASE}/login/`,
    logout: `${AUTH_BASE}/logout/`,
    refreshToken: `${AUTH_BASE}/token/refresh/`,
    user: `${AUTH_BASE}/user/`,
    changePassword: `${AUTH_BASE}/password/change/`,
  },
  nba: {
    boxscore: '',
    conference_manifest: '',
    franchise_history: '/api/v1/nba/franchise_history/',
    franchise_profile: '/api/v1/nba/franchise_profile/',
    franchise_season_manifest: '/api/v1/nba/franchise_season/manifest/',
    franchise_season_profile: '/api/v1/nba/franchise_season_profile',
    player_game_finder: '/api/v1/nba/player_game_finder/',
    player_span_finder: '/api/v1/nba/player_span_finder/',
    player_streak_finder: '/api/v1/nba/player_streak_finder/',
    players: '/api/v1/nba/person/',
    player_detail: '/api/v1/nba/player_profile',
    player_stats: '/api/v1/nba/player_profile_statistics',
    players_manifest: '/api/v1/nba/person/manifest/',
    team_game_finder: `${API_NBA_BASE}/team_game_finder/`,
    team_streak_finder: `${API_NBA_BASE}/team_streak_finder/`,
    team_span_finder: `${API_NBA_BASE}/team_span_finder/`,
    team_comebacks_finder: `${API_NBA_BASE}/comebacks/`,
    venue_manifest: '/api/v1/nba/game/venue_manifest/',
  },
  ncaambb: {
    boxscore: `${API_NCAAMBB_BASE}/boxscore/`,
    conference_manifest: `${API_NCAAMBB_BASE}/conference/`,
    franchise_history: `${API_NCAAMBB_BASE}/franchise_history/`,
    franchise_profile: `${API_NCAAMBB_BASE}/franchise_profile/`,
    franchise_season_manifest: `${API_NCAAMBB_BASE}/franchise_season/manifest/`,
    franchise_season_profile: `${API_NCAAMBB_BASE}/franchise_season_profile/`,
    player_game_finder: `${API_NCAAMBB_BASE}/player_game_finder/`,
    player_span_finder: `${API_NCAAMBB_BASE}/player_span_finder/`,
    player_streak_finder: `${API_NCAAMBB_BASE}/player_streak_finder/`,
    players: `${API_NCAAMBB_BASE}/person/`,
    player_detail: `${API_NCAAMBB_BASE}/player_profile`,
    player_stats: `${API_NCAAMBB_BASE}/player_profile_statistics`,
    players_manifest: `${API_NCAAMBB_BASE}/person/manifest/`,
    team_game_finder: `${API_NCAAMBB_BASE}/team_game_finder/`,
    team_streak_finder: `${API_NCAAMBB_BASE}/team_streak_finder/`,
    team_span_finder: `${API_NCAAMBB_BASE}/team_span_finder/`,
    venue_manifest: `${API_NCAAMBB_BASE}/game/venue_manifest/`,
  },
  ncaawbb: {
    boxscore: `${API_NCAAWBB_BASE}/boxscore/`,
    conference_manifest: `${API_NCAAWBB_BASE}/conference/`,
    franchise_history: `${API_NCAAWBB_BASE}/franchise_history/`,
    franchise_profile: `${API_NCAAWBB_BASE}/franchise_profile/`,
    franchise_season_manifest: `${API_NCAAWBB_BASE}/franchise_season/manifest/`,
    franchise_season_profile: `${API_NCAAWBB_BASE}/franchise_season_profile/`,
    player_game_finder: `${API_NCAAWBB_BASE}/player_game_finder/`,
    player_span_finder: `${API_NCAAWBB_BASE}/player_span_finder/`,
    player_streak_finder: `${API_NCAAWBB_BASE}/player_streak_finder/`,
    players: `${API_NCAAWBB_BASE}/person/`,
    player_detail: `${API_NCAAWBB_BASE}/player_profile`,
    player_stats: `${API_NCAAWBB_BASE}/player_profile_statistics`,
    players_manifest: `${API_NCAAWBB_BASE}/person/manifest/`,
    team_game_finder: `${API_NCAAWBB_BASE}/team_game_finder/`,
    team_streak_finder: `${API_NCAAWBB_BASE}/team_streak_finder/`,
    team_span_finder: `${API_NCAAWBB_BASE}/team_span_finder/`,
    venue_manifest: `${API_NCAAWBB_BASE}/game/venue_manifest/`,
  },
  search: '/api/v1/nba/search/',
  wnba: {
    boxscore: '',
    conference_manifest: '',
    franchise_history: `${API_WNBA_BASE}/franchise_history/`,
    franchise_profile: `${API_WNBA_BASE}/franchise_profile/`,
    franchise_season_manifest: `${API_WNBA_BASE}/franchise_season/manifest/`,
    franchise_season_profile: `${API_WNBA_BASE}/franchise_season_profile`,
    player_game_finder: `${API_WNBA_BASE}/player_game_finder/`,
    player_span_finder: `${API_WNBA_BASE}/player_span_finder/`,
    player_streak_finder: `${API_WNBA_BASE}/player_streak_finder/`,
    players: `${API_WNBA_BASE}/person/`,
    player_detail: `${API_WNBA_BASE}/player_profile`,
    player_stats: `${API_WNBA_BASE}/player_profile_statistics`,
    players_manifest: `${API_WNBA_BASE}/person/manifest/`,
    team_game_finder: `${API_WNBA_BASE}/team_game_finder/`,
    team_streak_finder: `${API_WNBA_BASE}/team_streak_finder/`,
    team_span_finder: `${API_WNBA_BASE}/team_span_finder/`,
    venue_manifest: `${API_WNBA_BASE}/game/venue_manifest/`,
  },
};

export default API_URLS;
