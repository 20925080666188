import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';

interface IProps {
  text: string;
  path: string;
  disabled?: boolean;
}

function SubNavListItem({ text, path, disabled = false }: IProps) {
  const location = useLocation();
  const { pathname } = location ?? { pathname: '' };

  return (
    <ListItemButton sx={{ pl: 7.5 }} component={Link} to={path} disabled={disabled} selected={ pathname.startsWith(path) }>
      <ListItemText primary={text} />
    </ListItemButton>    
  );
}

export default SubNavListItem;
