import React from 'react';
import { Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';

interface IProps {
  uniqueRowKey: string;
  headers: string[];
  data: any[];
  dataMap: any;
  labelHeaderRow?: React.ReactElement;
  headerCellStyles?: any;
  tableCellStyles?: any;
  size?: 'small' | 'medium';
  tableFooter?: React.ReactElement;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.custom.lighterGrey,
    textAlign: 'left',
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    textAlign: 'left',
  },
}));

const EMPTY_HEADERS = [
  'actions',
  'created',
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.custom.lightMediumGrey}`,
}));

function PaperContainer(props: any) {
  return <StyledPaper elevation={0} {...props} />;
}

function StyledTable({
  uniqueRowKey,
  headers,
  data,
  dataMap,
  labelHeaderRow,
  headerCellStyles,
  tableCellStyles={},
  size,
  tableFooter
}: IProps) {
  const cellValue = (attribute: string, index: number, value: any) => {
    switch (attribute.toLowerCase()) {
      case 'row':
        return index + 1;
      default:
        return value;
    }
  }

  const headersPartial = (
    <TableHead>
      { labelHeaderRow }
      <TableRow>
        <StyledTableCell key={headers[0]} sx={headerCellStyles}>{headers[0]}</StyledTableCell>
        {
          headers.slice(1).map((header: string) => {
            let displayHeader = header;
            if (EMPTY_HEADERS.includes(header.toLowerCase())) displayHeader = '';
            return <StyledTableCell key={header} align="right" sx={headerCellStyles}>{displayHeader}</StyledTableCell>
          })
        }
      </TableRow>
    </TableHead>
  );

  const bodyPartial = (
    <TableBody>
      {data.map((row, index) => (
        <TableRow
          key={row[uniqueRowKey]}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {
            headers.map((header: string) => {
              const key = `${row[uniqueRowKey]}-${header}`;
              const attribute = dataMap[header];
              const value = row[attribute];
              const cellStyles = tableCellStyles;
              if (header.toLowerCase() === 'query') {
                // JN: this is probably an awful way to do this
                cellStyles.maxWidth = '60em';
              }
              return (
                <StyledTableCell key={key} align="right" sx={tableCellStyles}>
                  { cellValue(attribute, index, value) }
                </StyledTableCell>
              );
            })
          }
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <TableContainer component={PaperContainer}> 
      <Table size={size}>
        { headersPartial }
        { bodyPartial }
        { tableFooter }
      </Table>
    </TableContainer>
  );
};

export default StyledTable;
