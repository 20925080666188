import { ModalFilterCategoriesType } from "../../types/modal";
import { FINDER_TYPES } from "../../utils/common/constants";
import { formatSeasonDisplay } from '../../utils/common/results';
import { CURRENT_SEASON_YEAR } from '../../utils/ncaawbb/constants';
import { FinderSelectOptionType } from '../../compounds/FastFilters/constants';

export const CURRENT_SEASON = formatSeasonDisplay(CURRENT_SEASON_YEAR);
export const TEAM_AP_RANK_CHIP = 'Team AP Rank[EQUALITY_VALUE][INPUT_VALUE]';
export const OPP_APP_RANK_CHIP = 'OPP AP Rank[EQUALITY_VALUE][INPUT_VALUE]';

export const ncaaWbbgameTypeStatusOptions: FinderSelectOptionType[] =[
  { id: 'any', label: 'Any'},
  { id: 'regular-season', label: 'Regular Season'},
  { id: 'conference', label: 'Regular Season Conference Games'},
  { id: 'non-conference', label: 'Regular Season Non-Conference Games'},
  { id: 'ncaa-tournament', label: 'NCAA Tournament'},
];

export const teamAdvancedModalFilterCategories: ModalFilterCategoriesType[] = [
  {
    category: 'season',
    categoryLabel: 'Season',
    filters: [
      {
        id: 'season_group',
        label: 'Current Season',
        subLabel: '',
        searchTerms: [''],
        filterType: 'radio',
        chip: 'season',
        radioGroup: [
          {
            id: 'current_season',
            label: 'Current Season',
            subLabel: 'By default, incudes regular season, conference and postseason tournaments',
            value: CURRENT_SEASON,
            type: 'default',
            chip: 'Current Season',
          },
          {
            id: 'season_range',
            label: 'Season Range',
            subLabel: '',
            value: '',
            type: 'custom_season_range',
            chip: '[INPUT_VALUE] - [INPUT_END_VALUE]',
          },
        ]
      },
    ]
  },
  {
    category: 'ap_rank',
    categoryLabel: 'AP Rank',
    categorySubLabel: 'Game scores for AP ranked teams back to 2002-03. Player and team game stats back to 2009-10.  Use >= 26 for unranked teams',
    filters: [
      {
        id: 'team_ap_rank',
        label: 'Team AP Rank',
        subLabel: "Limit data set to games that meet rank criteria",
        searchTerms: ['Team AP Rank'],
        filterType: 'equalityNumeric',
        // chip: 'Team AP Rank[EQUALITY_VALUE][INPUT_VALUE]'
        chip: TEAM_AP_RANK_CHIP,
        childTypes: [
          {
            id: 'team_ap_rank_child_01',
            label: 'End streak if rank criteria not met (do not limit the data set before applying criteria)',
            filterType: 'checkbox',
            finderTypes: [
              FINDER_TYPES.PLAYER_STREAK,
              FINDER_TYPES.TEAM_STREAK
            ]
          }
        ]
      },
      {
        id: 'opponent_ap_rank',
        label: 'Opponent AP Rank',
        subLabel: "Limit data set to games that meet rank criteria",
        searchTerms: ['Opponent AP Rank'],
        filterType: 'equalityNumeric',
        // chip: 'Opp AP Rank[EQUALITY_VALUE][INPUT_VALUE]'
        chip: OPP_APP_RANK_CHIP,
        childTypes: [
          {
            id: 'opponent_ap_rank_child_01',
            label: 'End streak if rank criteria not met (do not limit the data set before applying criteria)',
            filterType: 'checkbox',
            finderTypes: [
              FINDER_TYPES.PLAYER_STREAK,
              FINDER_TYPES.TEAM_STREAK
            ]
          },
        ]
      },
    ]
  },
  {
    category: 'tournament_seeds',
    categoryLabel: 'Tournament Seeds',
    filters: [
      {
        id: 'team_seed',
        label: 'Team Seed',
        subLabel: "Team's seed in any selected tournament",
        searchTerms: ['Team Seed'],
        filterType: 'equalityNumeric',
        chip: 'Team Seed[EQUALITY_VALUE][INPUT_VALUE]'
      },
      {
        id: 'opponent_seed',
        label: 'Opponent Seed',
        subLabel: "Opponent's seed in any selected tournament",
        searchTerms: ['Opponent Seed'],
        filterType: 'equalityNumeric',
        chip: 'Opponent Seed[EQUALITY_VALUE][INPUT_VALUE]'
      },
    ]
  },
  {
    category: 'ncaa_tournament',
    categoryLabel: 'NCAA Tournament',
    filters: [
      {
        id: 'championship',
        label: 'Championship',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Championship'
      },
      {
        id: 'semi_finals',
        label: 'Semifinals',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Semifinals'
      },
      {
        id: 'elite_eight',
        label: 'Elite Eight',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Elite Eight'
      },
      {
        id: 'sweet_sixteen',
        label: 'Sweet 16',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Sweet 16'
      },
      {
        id: 'round_of_32',
        label: 'Round of 32',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Round 2'
      },
      {
        id: 'round_of_64',
        label: 'Round of 64',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Round 1'
      },
      {
        id: 'play_in_round',
        label: 'Play-In Round',
        subLabel: '',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'NCAA Play-In Round'
      },
    ]
  },
  // {
  //   category: 'conference_tournaments',
  //   categoryLabel: 'Conference Tournaments',
  //   filters: [
  //     {
  //       id: 'finals',
  //       label: 'Finals',
  //       subLabel: 'Includes Finals for any selected conference tournaments',
  //       searchTerms: [''],
  //       filterType: 'checkbox',
  //       chip: 'Conf. Tourney Finals'
  //     },
  //     {
  //       id: 'semifinals',
  //       label: 'Semifinals',
  //       subLabel: 'Includes Semifinals for any selected conference tournaments',
  //       searchTerms: [''],
  //       filterType: 'checkbox',
  //       chip: 'Conf. Tourney Semifinals'
  //     },
  //     {
  //       id: 'quarterfinals',
  //       label: 'Quarterfinals',
  //       subLabel: 'Includes Quarterfinals for any selected conference tournaments',
  //       searchTerms: [''],
  //       filterType: 'checkbox',
  //       chip: 'Conf. Tourney Quarterfinals'
  //     },
  //     {
  //       id: 'all_rounds',
  //       label: 'Any Round',
  //       subLabel: 'Includes all rounds for any selected conference tournaments',
  //       searchTerms: [''],
  //       filterType: 'checkbox',
  //       chip: 'Conf. Tourney Any Round'
  //     },
  //     // {
  //     //   id: 'a_ten_tournament',
  //     //   label: 'A-10 Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'A-10 Tourney'
  //     // },
  //     // {
  //     //   id: 'a_sun_tournament',
  //     //   label: 'A-Sun Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'A-Sun Tourney'
  //     // },
  //     // {
  //     //   id: 'acc_tournament',
  //     //   label: 'ACC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'ACC Tourney'
  //     // },
  //     // {
  //     //   id: 'america_east_tournament',
  //     //   label: 'America East Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'America East Tourney'
  //     // },
  //     // {
  //     //   id: 'american_athletic_tournament',
  //     //   label: 'American Athletic Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'American Athletic Tourney'
  //     // },
  //     {
  //       id: 'big_east_tournament',
  //       label: 'Big East Tournament',
  //       subLabel: '',
  //       searchTerms: [''],
  //       filterType: 'checkbox',
  //       chip: 'Big East Tourney'
  //     },
  //     // {
  //     //   id: 'big_sky_tournament',
  //     //   label: 'Big Sky Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Big Sky Tourney'
  //     // },
  //     // {
  //     //   id: 'big_south_tournament',
  //     //   label: 'Big South Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Big South Tourney'
  //     // },
  //     // {
  //     //   id: 'big_ten_tournament',
  //     //   label: 'Big Ten Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Big Ten Tourney'
  //     // },
  //     // {
  //     //   id: 'big_west_tournament',
  //     //   label: 'Big West Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Big West Tourney'
  //     // },
  //     // {
  //     //   id: 'big_twelve_tournament',
  //     //   label: 'Big XII Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Big XII Tourney'
  //     // },
  //     // {
  //     //   id: 'c_usa_tournament',
  //     //   label: 'C-USA Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'C-USA Tourney'
  //     // },
  //     // {
  //     //   id: 'caa_tournament',
  //     //   label: 'CAA Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'CAA Tourney'
  //     // },
  //     // {
  //     //   id: 'horizon_tournament',
  //     //   label: 'Horizon Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Horizon Tourney'
  //     // },
  //     // {
  //     //   id: 'ivy_tournament',
  //     //   label: 'Ivy Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Ivy Tourney'
  //     // },
  //     // {
  //     //   id: 'maac_tournament',
  //     //   label: 'MAAC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'MAAC Tourney'
  //     // },
  //     // {
  //     //   id: 'mac_tournament',
  //     //   label: 'MAC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'MAC Tourney'
  //     // },
  //     // {
  //     //   id: 'meac_tournament',
  //     //   label: 'MEAC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'MEAC Tourney'
  //     // },
  //     // {
  //     //   id: 'mountain_west_tournament',
  //     //   label: 'Mountain West Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Mountain West Tourney'
  //     // },
  //     // {
  //     //   id: 'mvc_tournament',
  //     //   label: 'MVC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'MVC Tourney'
  //     // },
  //     // {
  //     //   id: 'nec_tournament',
  //     //   label: 'NEC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'NEC Tourney'
  //     // },
  //     // {
  //     //   id: 'ovc_tournament',
  //     //   label: 'OVC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'OVC Tourney'
  //     // },
  //     // {
  //     //   id: 'pac_twelve_tournament',
  //     //   label: 'PAC-12 Tournament (FKA: PAC-10)',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'PAC-12 Tourney'
  //     // },
  //     // {
  //     //   id: 'patriot_league_tournament',
  //     //   label: 'Patriot League Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Patriot League Tourney'
  //     // },
  //     // {
  //     //   id: 'sec_tournament',
  //     //   label: 'SEC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'SEC Tourney'
  //     // },
  //     // {
  //     //   id: 'southern_tournament',
  //     //   label: 'Southern Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Southern Tourney'
  //     // },
  //     // {
  //     //   id: 'southland_tournament',
  //     //   label: 'Southland Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Southland Tourney'
  //     // },
  //     // {
  //     //   id: 'summit_tournament',
  //     //   label: 'Summit Tournament (FKA: Mid-Continent)',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Summit Tourney'
  //     // },
  //     // {
  //     //   id: 'sun_belt_tournament',
  //     //   label: 'Sun Belt Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'Sun Belt Tourney'
  //     // },
  //     // {
  //     //   id: 'swac_tournament',
  //     //   label: 'SWAC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'SWAC Tourney'
  //     // },
  //     // {
  //     //   id: 'wac_tournament',
  //     //   label: 'WAC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'WAC Tourney'
  //     // },
  //     // {
  //     //   id: 'wcc_tournament',
  //     //   label: 'WCC Tournament',
  //     //   subLabel: '',
  //     //   searchTerms: [''],
  //     //   filterType: 'checkbox',
  //     //   chip: 'WCC Tourney'
  //     // },
  //   ]
  // },
  {
    category: 'other_postseason_tournaments',
    categoryLabel: 'Other Postseason Tournaments',
    filters: [
      {
        id: 'wbi_tournament',
        label: 'Women\'s Basketball Invitational (WBI)',
        subLabel: 'Tournament started in 2009-10',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'WBI Tourney'
      },
      {
        id: 'wbit_tournament',
        label: 'Women\'s Basketball Invitation Tournament (WBIT)',
        subLabel: 'Tournament started in 2023-24',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'WBIT Tourney'
      },
      {
        id: 'wnit_tournament',
        label: 'Women\'s National Invitation Tournament (WNIT)',
        subLabel: 'Tournament started in 1997-98',
        searchTerms: [''],
        filterType: 'checkbox',
        chip: 'WNIT Tourney'
      },
    ]
  },
];

// playerAdvancedFilterCategories is simply teamAdvancedModalFilterCategories
// with a 'player' category stuck on the front for the time being. If this
// needs to change, feel free to change the following .concat() call.

// We don't have player class currently, but when it's added later, we can add this back
// export const advancedModalFilterCategories: ModalFilterCategoriesType[] = [
//   {
//     category: 'player',
//     categoryLabel: 'Player',
//     filters: [
//       {
//         id: 'seasons',
//         label: 'Class',
//         subLabel: '1=freshman, 2=sophomore, etc.',
//         searchTerms: ['Seasons in League'],
//         filterType: 'equalityNumeric',
//         chip: 'Class[EQUALITY_VALUE][INPUT_VALUE]',
//       },
//     ]
//   },
// ].concat(teamAdvancedModalFilterCategories);

export const advancedModalFilterCategories: ModalFilterCategoriesType[] = teamAdvancedModalFilterCategories;