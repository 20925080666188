import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    name: string;
    link: string;
}

export default function TeamLink({ name, link }: IProps) {
    return link !== '' ? <Link to={link}>{name}</Link> : <span>{name}</span>;
};
