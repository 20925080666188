import React from 'react';
import { Grid, TableCell, TableRow } from '@mui/material';
import StyledGroupingTable from '../../atoms/StyledGroupingTable/StyledGroupingTable';

const standardGroupings = [
  'MIN',
  'FG',
  'FGA',
  '3P',
  '3PA',
  'FT',
  'FTA',
  'OREB',
  'DREB',
  'REB',
  'AST',
  'STL',
  'BLK',
  'TO',
  'PF',
  'PTS',
];

const starterGrouping = [ 'Starters', ...standardGroupings ];
const benchGrouping = [ 'Bench', ...standardGroupings ];

const standardMap = {
  'MIN': 'minutes',
  'FG': 'fieldGoals',
  'FGA': 'fieldGoalAttempts',
  '3P': 'threePointFgs',
  '3PA': 'threePointFgas',
  'FT': 'freeThrows',
  'FTA': 'freeThrowAttempts',
  'OREB': 'offensiveRebounds',
  'DREB': 'defensiveRebounds',
  'REB': 'rebounds',
  'AST': 'assists',
  'STL': 'steals',
  'BLK': 'blocks',
  'TO': 'turnovers',
  'PF': 'personalFouls',
  'PTS': 'points',
};

const starterMap = { 'Starters': 'playerLink', ...standardMap };
const benchMap = { 'Bench': 'playerLink', ...standardMap };

interface IPlayerGameData {
  playerId: number;
  playerName: string;
  playerLink: JSX.Element;
  minutes: number;
  fieldGoals: number;
  fieldGoalAttempts: number;
  threePointFgs: number;
  threePointFgas: number;
  freeThrows: number;
  freeThrowAttempts: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  rebounds: number;
  assists: number;
  steals: number;
  blocks: number;
  turnovers: number;
  personalFouls: number;
  points: number;
  started: boolean;
};

interface IProps {
  teamName: string;
  coachName: string;
  data: IPlayerGameData[];
  teamLogoUrl?: string;
}

interface Lineup {
  starters: IPlayerGameData[];
  bench: IPlayerGameData[];
}

const getStartersAndBench = (data: IPlayerGameData[]): Lineup => {
  const starters: IPlayerGameData[] = [];
  const bench: IPlayerGameData[] = [];

  data.forEach((player) => {
    if (player.started) {
      starters.push(player);
    } else {
      bench.push(player);
    }
  });

  return { starters, bench };
}

function GamePlayerStatsTable({ teamName, coachName, teamLogoUrl, data }: IProps) {
  const { starters, bench } = getStartersAndBench(data);

  return (
    <Grid container mt={5}>
      <StyledGroupingTable
        uniqueRowKey='playerId'
        groupings={[ starterGrouping, benchGrouping ]}
        datasets={[ starters, bench ]}
        dataMaps={[ starterMap, benchMap ]}
        labelHeaderRow={
          <TableRow>
            { coachName ? 
            <>
              <TableCell colSpan={1}>
                <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {teamLogoUrl && <img src={teamLogoUrl} height="50" width="50" alt="team-logo" /> }
                  <h5 style={{ fontSize: '18px', margin: 0, }}>{ teamName }</h5>
                </span>
              </TableCell>
              <TableCell colSpan={17} align='right'>
                <span style={{ fontSize: '14px', margin: 0, fontWeight: 600, marginRight: 20, }}>Head Coach</span>
                <span style={{ fontSize: '14px', margin: 0, }}>{ coachName }</span>
              </TableCell>
            </> :
            <TableCell colSpan={18}>
              <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {teamLogoUrl && <img src={teamLogoUrl} height="50" width="50" alt="team-logo" /> }
                <h5 style={{ fontSize: '18px', margin: 0, }}>{ teamName }</h5>
              </span>
            </TableCell>
            }
            
          </TableRow>
        }
        groupingCellStyles={{
          padding: '10px',
          fontSize: '12px',
        }}
        tableCellStyles={{
          padding: '10px',
          fontSize: '14px',
        }}
      />
    </Grid>
  );
};

export default GamePlayerStatsTable;