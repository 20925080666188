import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, groupByOptions as ncaaGroupByOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import PlayerGameGroupBy from '../../../compounds/PlayerGameGroupBy/PlayerGameGroupBy';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaWbbPlayerFiltersLayout from '../../../compounds/ncaawbb/NcaaWbbPlayerFiltersLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/ncaawbb/search';
import { FINDER_TYPES } from '../../../utils/common/constants';

interface IProps extends WithCopyableUrlProps {};

function PlayerGameFinderSearch({ handleDone }: IProps) {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const groupByPartial = <PlayerGameGroupBy
    groupByOptions={ncaaGroupByOptions}
  />
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
      finderType={FINDER_TYPES.PLAYER_GAME}
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <NcaaWbbPlayerFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            groupByPartial={groupByPartial}
            apiUrls={API_URLS.ncaawbb}
            bottomModalPartial={bottomModalPartial}
          />
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  PlayerGameFinderSearch,
  ROUTER_URLS.ncaawbb.playerGameFinderResults,
  statsFilters,
  appendContextFilters,
  FINDER_TYPES.PLAYER_GAME,
  advancedModalFilterCategories,
);
