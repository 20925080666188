import React from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LeagueType, useLeagueContext, useLeagueDispatchContext } from '../../contexts/LeagueContext';
import ROUTER_URLS from '../../utils/router/urls';

const StyledSelect = styled(Select)(() => ({
  backgroundColor: '#1570ef',
  marginLeft: 40,
  marginRight: 0,
  color: '#FFF',
  height: '39px',
  borderRadius: '8px 8px 8px 8px',
  width: '150px',
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1570ef"
    },
    "&:hover fieldset": {
      borderColor: "#1570ef"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1570ef"
    }
  },
  '& .MuiSvgIcon-root': {
    color: '#FFF',
 }
}));

function LeagueSelect() {
  const leagueContext: any = useLeagueContext();
  const leagueDispatchContext: any = useLeagueDispatchContext();
  const league = leagueContext?.selectedLeague?.toUpperCase() || 'NBA';
  const navigate = useNavigate();

  const handleLeagueChange = (event: SelectChangeEvent<any>) => {
    leagueDispatchContext({
      type: 'leagueChanged',
      selectedLeague: event.target.value,
    });

    if (event.target.value && event.target.value.length > 0) {
      const routerLinks = event.target.value.toLowerCase() as LeagueType;
      navigate(ROUTER_URLS[routerLinks].playerGameFinderSearch);
    }
  };

  return (
    <FormControl>
      <StyledSelect
        value={league}
        onChange={handleLeagueChange}
        IconComponent={ExpandMoreIcon}
      >
        <MenuItem value="NBA">NBA</MenuItem>
        <MenuItem value="NCAAMBB">NCAA-MBB</MenuItem>
        <MenuItem value="NCAAWBB">NCAA-WBB</MenuItem>
        <MenuItem value="WNBA">WNBA</MenuItem>
      </StyledSelect>
    </FormControl>
  );
}

export default LeagueSelect;
