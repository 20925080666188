import React from 'react';
import ModalOverlay from '../ModalOverlay/ModalOverlay';
import NcaaMbbTeamDataInfoModal from '../../pages/NcaaMbb/TeamDataInfo/NcaaMbbTeamDataInfoModal';

export default function NcaaMbbTeamDataInfo() {
    return (
        <ModalOverlay
            heading="NCAA Men's Basketball Team Stats Data Coverage"
            subheading="Team stats go as far back as the first season listed, unless otherwise noted."
            buttonText="See how far your search went back in time."
        >
            <NcaaMbbTeamDataInfoModal />
        </ModalOverlay>
    );
}