import React, { useEffect, useState } from 'react';
import {
  GridFooter,
  GridFooterContainer,
} from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { LeagueType, useLeagueContext } from '../../contexts/LeagueContext';
import { fetchLastAdfRun } from '../../../api/api';

export default function LastUpdated() {
    const leagueContext: any = useLeagueContext();
    const league: LeagueType = leagueContext?.selectedLeague?.toLowerCase() || 'nba';

    const [ lastUpdated, setLastUpdated ] = useState<string | null>(null);

    useEffect(() => {
        fetchLastAdfRun(league)
        .then((response) => {
            setLastUpdated(response.last_successful_run);
        })
        .catch(() => {
            setLastUpdated(null);
        });
    }, [ league ]);

    return (
      <GridFooterContainer>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
          <Typography variant="body2" color="textSecondary">
            { lastUpdated ? 
                `Results last updated ${lastUpdated}` :
                `Results updated more than 30 days ago`
            }
          </Typography>
        </Box>
        <GridFooter />
      </GridFooterContainer>
    );
  }
