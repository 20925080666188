import { ColumnMappingType } from "../../types/results";

const DEFAULT_COL_WIDTH = 80;
const MEDIUM_COL_WIDTH = 145;
const WIDE_COL_WIDTH = 185; // fits longest player display name at 24 characters

export type ComebackColumnKeyType = 'gameType' |
                             'gameDate' | 
                             'result' |
                             'teamName' |
                             'opponentName' |
                             'largestDeficitPts' |
                             'largestDeficitScore' |
                             'largestDeficitTime'
                             ;

export const COMEBACK_COLUMNS: { [key in ComebackColumnKeyType]: ColumnMappingType } = {
  gameDate: {
    headerName: 'Game Date',
    field: 'game__date',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  gameType: {
    headerName: 'Game Type',
    field: 'game_type',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  result: {
    headerName: 'Result',
    field: 'result',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
    sortable: false,
  },
  teamName: {
    headerName: 'Team',
    field: 'team',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  opponentName: {
    headerName: 'Opponent',
    field: 'opponent',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  largestDeficitPts: {
    headerName: 'Largest Deficit (PTS)',
    field: 'largest_deficit',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  largestDeficitScore: {
    headerName: 'Score at Largest Deficit',
    field: 'score_at_largest_deficit',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
    sortable: false,
  },
  largestDeficitTime: {
    headerName: 'Time of Largest Deficit',
    field: 'time_of_largest_deficit',
    display: 'alwaysFirst',
    sortable: false,
    flex: 1,
    minWidth: WIDE_COL_WIDTH,
  },
};

export const BLOWN_LEAD_COLUMNS: { [key in ComebackColumnKeyType]: ColumnMappingType } = {
  ...COMEBACK_COLUMNS,
  largestDeficitPts: {
    headerName: 'Largest Lead (PTS)',
    field: 'largest_deficit',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  largestDeficitScore: {
    headerName: 'Score at Largest Lead',
    field: 'score_at_largest_deficit',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
    sortable: false,
  },
  largestDeficitTime: {
    headerName: 'Time of Largest Lead',
    field: 'time_of_largest_deficit',
    display: 'alwaysFirst',
    sortable: false,
    flex: 1,
    minWidth: WIDE_COL_WIDTH,
  },
};
