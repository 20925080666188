import React from 'react';
import ModalOverlay from '../ModalOverlay/ModalOverlay';
import NcaaMbbGameDataInfoModal from '../../pages/NcaaMbb/GameDataInfo/NcaaMbbGameDataInfoModal';

export default function NcaaMbbGameDataInfo() {
    return (
        <ModalOverlay
            heading="NCAA Men's Basketball Data Coverage"
            subheading="AccessElias NCAA Men's Basketball stats go as far back as the first season listed, unless otherwise noted."
            buttonText="See how far your search went back in time."
        >
            <NcaaMbbGameDataInfoModal />
        </ModalOverlay>
    );
}