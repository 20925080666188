import { StatFilter, StatFilterType } from "../../../compounds/EliasStatsFilter/filter-stats-utils";

export const PAGE_TITLE = 'NBA Team Comebacks Finder';
export const PAGE_SUBTITLE =
  'Search the most complete and accurate NBA database for team comebacks and blown leads stats';

export type FinderSelectOptionType = {
  id: string;
  label: string;
};

export const groupByOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'None' },
  { id: 'team', label: 'Team instances' },
  { id: 'team-season', label: 'Team within a season' },
  { id: 'team-opponent', label: 'Team against an opponent' },
];

export const DEFAULT_GROUP_BY_STATUS = groupByOptions[0].id;

export const statsFilters: StatFilter[] = [
  {
    id: 'comebacks',
    abbr: 'Comeback',
    name: 'Comeback',
    year: 1997,
    type: StatFilterType.equality,
    explanation: 'Min. 10 PTS',
  },
  {
    id: 'blown_leads',
    abbr: 'Blown Lead',
    name: 'Blown Lead',
    year: 1997,
    type: StatFilterType.equality,
    explanation: 'Min. 10 PTS',
  },
]
