import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import StyledTable from '../../atoms/StyledTable/StyledTable';
import FactCard from '../../atoms/FactCard/FactCard';
import { FranchiseDetailType, FranchiseHistoryType } from '../../types/franchise';
import { formatSeasonDisplay } from '../../utils/common/results';
import { percentToFixed } from '../../utils/common/string';

interface IProps {
  teamDetail: FranchiseDetailType;
  teamHistory: FranchiseHistoryType[];
  teamHeading: string;
};

const tableHeaders = [
  // 'Row',
  'Season',
  // 'Conf',
  'Overall W',
  'Overall L',
  'Overall W-L%',
  'Conf W',
  'Conf L',
  'Conf W-L%',
  'NCAA Tournament',
  'Seed',
  'Coach',
];

const dataMap = {
  'Row': 'Row',
  'Season': 'season',
  // 'Conf': 'conference_id',
  'Overall W': 'overall_wins',
  'Overall L': 'overall_losses',
  'Overall W-L%': 'overall_win_percentage',
  'Conf W': 'conference_wins',
  'Conf L': 'conference_losses',
  'Conf W-L%': 'conference_win_percentage',
  'NCAA Tournament': 'ncaa_tournament_result',
  'Seed': 'ncaa_tournament_seed',
  'Coach': 'coaches',
};

const formatTeamHistoryData = (data: FranchiseHistoryType[]) => 
  data.map((row) => ({
    id: row.id,
    season: formatSeasonDisplay(row.season),
    overall_wins: row.overall_wins,
    overall_losses: row.overall_losses,
    overall_win_percentage: percentToFixed(row.overall_win_percentage, 3),
    conference_wins: row.conference_wins,
    conference_losses: row.conference_losses,
    conference_win_percentage: percentToFixed(row.conference_win_percentage, 3),
    ncaa_tournament_result: row.ncaa_tournament_result,
    ncaa_tournament_seed: row.ncaa_tournament_seed,
    coaches: row.coaches,
  }));

function TeamHistoryTab({ teamDetail, teamHistory, teamHeading }: IProps) {
  const formattedRecord = `${teamDetail.total_wins}-${teamDetail.total_losses} (${percentToFixed(teamDetail.total_winning_percentage, 3)}) W-L (%)`;
  const formattedData = formatTeamHistoryData(teamHistory);

  return (
    <Box>
      <Typography component="div" variant="h6" fontWeight="600">{teamDetail.full_name} {teamHeading} History</Typography>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={3}>
          <FactCard label='Location' values={[`${teamDetail.city}, ${teamDetail.state}`]} />
          <FactCard label='Record' values={[formattedRecord]} />
        </Grid>
        <Grid item xs={3}>
          <FactCard label='Current Conference' values={[teamDetail.conference_name]} />
          { 
            // <FactCard label='Conference Tournament Champion' values={['6 Tournament win(s)', 'Last win: 1995-96']} />
          }
        </Grid>
        <Grid item xs={3}>
          <FactCard 
            label='NCAA Tournament' 
            values={[
              `${teamDetail.ncaa_tournament_appearances} Appearance${teamDetail.ncaa_tournament_appearances !== 1 ? 's' : ''}`,
              `${teamDetail.ncaa_tournament_wins} Championship${teamDetail.ncaa_tournament_wins !== 1 ? 's' : ''}`,
            ]} 
          />
          {
            // <FactCard label='NCAA Champion' values={['1991-92']} />
          }
        </Grid>
      </Grid>
      <Grid container mt={5}>
        <Grid item xs={12} marginRight={5}>
          <StyledTable uniqueRowKey='id' headers={tableHeaders} data={formattedData} dataMap={dataMap} />
        </Grid>  
      </Grid>
    </Box>
  );
}

export default TeamHistoryTab;
