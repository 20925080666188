import React from 'react';
import ROUTER_URLS from '../../../utils/router/urls';
import Header from '../../../compounds/Common/Header';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import { statsFilters, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories, comebacksModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NbaTeamFiltersLayout from '../../../compounds/nba/NbaTeamFiltersLayout';
import withCopyableUrl, { WithCopyableUrlProps } from '../../../compounds/CopyableUrlWrapper/CopyableUrlWrapper';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/nba/search';

interface IProps extends WithCopyableUrlProps {};

function ComebacksFinderSearch({ handleDone }: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo />;
  const groupByPartial = null;
  const gameTypeModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={comebacksModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );
  const advancedModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
      comingSoon
    />
  );

  return (
    <Dashboard>
      <FinderStackLayout
        header={<Header title={PAGE_TITLE} subtitle={PAGE_SUBTITLE} />}
        topPanel={
          <NbaTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.nba}
            topModalPartial={gameTypeModalPartial}
            bottomModalPartial={advancedModalPartial}
            groupByPartial={groupByPartial}
            showHalfQuarter
          />
        }
      />
    </Dashboard>
  );
}

export default withCopyableUrl(
  ComebacksFinderSearch,
  ROUTER_URLS.nba.comebacksFinderResults,
  statsFilters,
  appendContextFilters
);
