import React from 'react';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { fetchNcaaMbbPlayerStreakStats } from '../../../../api/ncaambb';
import PlayerStreakFilters from '../../../compounds/PlayerStreakFilters/PlayerStreakFilters';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import PlayerStreakTable from '../../../compounds/PlayerStreakTable/PlayerStreakTable';
import { statsFilters, streakRangeOptions, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { advancedModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaMbbPlayerFiltersLayout from '../../../compounds/ncaambb/NcaaMbbPlayerFiltersLayout';
import { mapRows } from '../../../utils/ncaambb/streak-finder/map-results';
import { mapColumns } from '../../../utils/ncaambb/streak-finder/map-columns';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import withResultsLayout, { WithResultsLayoutProps } from '../../../compounds/ResultsWrapper/ResultsWrapper';
import { appendContextFilters } from '../../../utils/ncaambb/search';
import Header from '../../../compounds/Common/Header';
import EXTERNAL_URLS from '../../../utils/router/external_urls';
import NcaaMbbGameDataInfo from '../../../compounds/ncaambb/NcaaMbbGameInfo';
import { FINDER_TYPES } from '../../../utils/common/constants';

interface IProps extends WithResultsLayoutProps {};

function PlayerStreakFinderResults({
  columnHeaders,
  resultsData,
  tableDataLoading,
  filtersLoading,
  error,
  handleDone,
  pageOrSortDataFetch
}: IProps) {
  const pageSpecificSearchEnabled = (context: ContextFilterType): boolean =>
    Boolean(context.streakGameNumber) && Number(context.streakGameNumber) > 1;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const bottomModalPartial = (
    <ModalFilters
      buttonTitle='Advanced Filters'
      filters={advancedModalFilterCategories}
      filterType='advancedFilters'
      finderType={FINDER_TYPES.PLAYER_STREAK}
    />
  );

  return (
    <Dashboard loading={filtersLoading}>
      <FinderStackLayout
        header={
          <Header
            title={PAGE_TITLE}
            subtitle={PAGE_SUBTITLE}
            backButton={
              <EliasBackButton route={ROUTER_URLS.ncaambb.playerStreakFinderSearch} />
            }
          />
        }
        topPanel={
          <NcaaMbbPlayerFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.ncaambb}
            bottomModalPartial={bottomModalPartial}
          >
            <PlayerStreakFilters streakRangeOptions={streakRangeOptions} />
          </NcaaMbbPlayerFiltersLayout>
        }
        bottomPanel={
          tableDataLoading || error ?
          <ResultsLoading error={error} /> :
          <PlayerStreakTable
            data={resultsData?.results ?? []}
            dataCount={resultsData?.count ?? 0}
            dataMetaInfo={resultsData?.query?.back_to ?? []}
            columnData={columnHeaders || []}
            handleDone={pageOrSortDataFetch}
            linkUrls={EXTERNAL_URLS.NCAAM}
            explanationMessage={ <NcaaMbbGameDataInfo /> }
          />
        }
      />
    </Dashboard>
  );
}

export default withResultsLayout(
  PlayerStreakFinderResults,
  ROUTER_URLS.ncaambb.playerStreakFinderResults,
  statsFilters,
  fetchNcaaMbbPlayerStreakStats,
  mapColumns,
  mapRows,
  appendContextFilters,
  true, // translateNcaamFilters
  FINDER_TYPES.PLAYER_STREAK,
  advancedModalFilterCategories,
);
