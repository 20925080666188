import React from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import API_URLS from '../../../../api/api_urls';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import PlayerLayout from '../../../compounds/PlayerLayout/PlayerLayout';
import { useFetchPlayerDetail, useFetchPlayerStats } from '../../../hooks/useFetch';
import PageTitle from '../../../atoms/PageTitle/PageTitle';

function NcaaWbbPlayer() {
  const { playerId } = useParams();

  const apiUrl = API_URLS.ncaawbb;

  const { data: playerProfileData, isLoading: isProfileLoading, error: profileError } = useFetchPlayerDetail(playerId || '', apiUrl.player_detail);
  const { data: playerStatsData, isLoading: isStatsLoading, error: statsError } = useFetchPlayerStats(playerId || '', apiUrl.player_stats);

  if (isProfileLoading || isStatsLoading) {
    return <span>Loading...</span>
  }

  if (profileError && profileError instanceof Error) {
    return <span>Error: {profileError.message}</span>
  }

  if (statsError && statsError instanceof Error) {
    return <span>Error: {statsError.message}</span>
  }

  if (!playerProfileData || !playerStatsData) {
    return <span>Error: Api failed to respond</span>
  }

  const { name } = playerProfileData;
  return (
    <>
      <PageTitle
        title={name}
        titleTemplate="%s - NCAA Women's Basketball"
      />
      <Dashboard>
        <Container sx={{ py: 2 }} maxWidth="xl">
          <PlayerLayout playerDetails={playerProfileData} statsData={playerStatsData} />
        </Container>
      </Dashboard>
    </>
  );
}

export default NcaaWbbPlayer;
