import { LeagueType } from '../../contexts/LeagueContext';
import { LOGO_BASE_URL } from './constants';

export interface ITeamLogoUrlProps {
    franchiseId?: number;
    league: LeagueType;
}

export function getTeamLogoUrl({ franchiseId, league }: ITeamLogoUrlProps) {
    if (league === 'ncaambb' || league === 'ncaawbb') {
        return `${LOGO_BASE_URL}${franchiseId ?? 'default'}.png`;
    }

    return undefined;
};
