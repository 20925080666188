import React from 'react';
import StyledTable from '../../../atoms/StyledTable/StyledTable';
import backInTimeInfo from './constants';

const headers = [
  'Category',
  'Season',
  'Notes',
];

const rowMap = {
  'Category': 'tournamentName',
  'Season': 'seasonCutoff',
  'Notes': 'notes',
}

export default function NcaaMbbGameDataInfoModal() {
  return (
    <StyledTable
      uniqueRowKey='No'
      headers={headers}
      data={backInTimeInfo}
      dataMap={rowMap}
    />
  );
};
